// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard
import TimelineItem from "components_base/Timeline/TimelineItem";

// Context Dashboard
import { DashboardContext } from "context/DashboardContext";
import { useContext } from "react";
import { Divider } from "@mui/material";
import { parseJsonData } from "utils/util";

function AnnualFilingsView() {
  const { corpSelected } = useContext(DashboardContext);

  return (
    <Card sx={{ height: "100%", width: "100%", display: corpSelected ? "flex" : "none" }}>
      <MDBox pt={3} px={3}>
        <Divider />
        <MDTypography variant="h6" fontWeight="medium">
          <MDTypography display="inline" variant="body2" verticalAlign="middle" mr={2}>
            <Icon fontSize="medium">picture_as_pdf</Icon>
          </MDTypography>
          Articles
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        {corpSelected.corp_url_articles_filings !== null ? (
          parseJsonData(corpSelected.corp_url_articles_filings).map((article) => (
            <TimelineItem
              color={
                corpSelected.corp_url_articles_filings !== null
                  ? "success"
                  : "error"
              }
              icon={
                corpSelected.corp_url_articles_filings !== null ? "done" : "clear"
              }
              title={article.name}
              url={article.url}
            />
          ))
        ) : (
          <TimelineItem
            color={
              corpSelected.corp_url_annual_filings !== null ? "success" : "error"
            }
            icon={corpSelected.corp_url_annual_filings !== null ? "done" : "clear"}
            title="No records on file"
          />
        )}
      </MDBox>
      <Divider />
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          <MDTypography display="inline" variant="body2" verticalAlign="middle" mr={2}>
            <Icon fontSize="medium">picture_as_pdf</Icon>
          </MDTypography>
          Annual Filings
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        {corpSelected.corp_url_annual_filings !== null ? (
          parseJsonData(corpSelected.corp_url_annual_filings).map((article) => (
            <TimelineItem
              color={
                corpSelected.corp_url_annual_filings !== null ? "success" : "error"
              }
              icon={
                corpSelected.corp_url_annual_filings !== null ? "done" : "clear"
              }
              title={article.name}
              url={article.url}
            />
          ))
        ) : (
          <TimelineItem
            color={
              corpSelected.corp_url_annual_filings !== null ? "success" : "error"
            }
            icon={corpSelected.corp_url_annual_filings !== null ? "done" : "clear"}
            title="No records on file"
          />
        )}
      </MDBox>
      <Divider />
    </Card>
  );
}

export default AnnualFilingsView;
