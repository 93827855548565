// search corp by name
/**
 * It takes a string as an argument, sends it to a server, and returns the response from the server
 * @param name - The name of the corporation you want to search for.
 * @returns An array of objects.
 */
export async function searchCorporationByName(name) {
  let result;
  const requestAuthToken = localStorage.getItem("auth_token");
  const data = JSON.stringify({
    corp_name: name,
  });
  await fetch("https://api.ceyba.io/api/search-by-name.json", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "auth-token": requestAuthToken,
    },
    body: data,
  })
    .then((response) => response.json())
    .then((res) => {
      result = res.corporations;
    })
    .catch(() => {
      result = [];
    });

  return result;
}

/**
 * It takes a JSON object as a parameter, sends it to a server, and returns the response from the
 * server.
 * </code>
 * @param searchOptions - {
 * @returns An array of objects.
 */
export async function advanceSearch(searchOptions) {
  let result;
  const requestAuthToken = localStorage.getItem("auth_token");
  const data = JSON.stringify(searchOptions);
  await fetch("https://api.ceyba.io/api/advanced-search.json", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "auth-token": requestAuthToken,
    },
    body: data,
  })
    .then((response) => response.json())
    .then((res) => {
      result = res.corporations;
    })
    .catch(() => {
      result = [];
    });

  return result;
}

/**
 * It returns an array of zip codes from a remote API.
 * @returns An array of zip codes.
 */
export async function getAllZipCodes() {
  let result = [];
  const requestAuthToken = localStorage.getItem("auth_token");
  try {
    let response = await fetch(
      "https://api.ceyba.io/api/pr-all-zip-codes.json",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "auth-token": requestAuthToken,
        },
      }
    );
    result = response.json();
  } catch (error) {
    result = [];
  }
  return result;
}
