/*
=========================================================
* Ceyba
=========================================================
Coded by Andres Lista
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the
Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons
import MonetizationOnIcon from "@mui/icons-material/QueryStats";
import CategoryIcon from "@mui/icons-material/Category";
import logoCeybaTransp from "assets/images/logo-ceyba-transp.png";

// Material Dashboard
import MDBox from "components/MDBox";

// Material Dashboard
import DashboardLayout from "components_base/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components_base/Navbars/DashboardNavbar";
import Footer from "components_base/Footer";
// import ReportsBarChart from "components_base/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "components_base/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "components_base/Cards/StatisticsCards/ComplexStatisticsCard";
import ComplexCardPurpose from "components_base/Cards/StatisticsCards/ComplexCardPurpose";
import InfoCard from "components_base/Cards/InfoCards/InfoCard";

// Data
// import officersDataTable from "layouts/tables/data/officersDataTable";

// Context Dashboard
import { DashboardContext } from "context/DashboardContext";

// Dashboard components
import AnnualFilingsView from "layouts/dashboard/components/AnnualFilingsView";
import MDTypography from "components/MDTypography";
import { useContext /* useState */ } from "react";
import {
  Card,
  Hidden /* Icon, Menu, MenuItem */,
  Icon,
  Link,
} from "@mui/material";
// import DataTableResults from "components_base/Tables/DataTableResults";
import { formatter, normalizeDate } from "utils/util";
import { getRevenuesValue } from "utils/util";
// import { CSVLink } from "react-csv";

/**
 * It's a function that returns a component that renders a dashboard
 * @returns a JSX element.
 */
function Dashboard() {
  const { corpSelected } = useContext(DashboardContext);
  // const { columns, rows } = officersDataTable();
  // const [csvData, setCsvData] = useState([]);
  // const [menu, setMenu] = useState(null);

  // const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  // const closeMenu = () => setMenu(null);

  // const handleGenerateCSV = () => {
  //   if (corpSelected.corp_email !== undefined) {
  //     const rawData = corpSelected.corp_email;
  //     const mData = rawData.replace(/'/g, '"');
  //     setCsvData(JSON.parse(mData));
  //     return true;
  //   }
  //   return false;
  // };

  // const renderMenu = (
  //   <Menu
  //     id="simple-menu"
  //     anchorEl={menu}
  //     anchorOrigin={{
  //       vertical: "top",
  //       horizontal: "left",
  //     }}
  //     transformOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     open={Boolean(menu)}
  //     onClose={closeMenu}
  //   >
  //     <CSVLink
  //       headers={["name", "email", "title"]}
  //       data={csvData}
  //       filename={"ceyba_data_".concat(new Date().toLocaleDateString("en-US")).concat(".csv")}
  //       onClick={handleGenerateCSV}
  //     >
  //       <MenuItem onClick={closeMenu}>
  //         <span>Export emails to CSV</span>
  //         <Icon sx={{ cursor: "pointer", fontWeight: "bold", marginLeft: 2 }} fontSize="small">
  //           file_download
  //         </Icon>
  //       </MenuItem>
  //     </CSVLink>
  //   </Menu>
  // );

  return (
    <div data-aos="fade-in">
      <DashboardLayout>
        <DashboardNavbar />
        <Hidden xsUp>
          <MDBox
            display={corpSelected ? "none" : "flex"}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: "80vh" }}
            >
              <MDBox
                display={corpSelected ? "none" : "flex"}
                justifyContent="center"
                alignItems="center"
                style={{ height: "100%" }}
              >
                <Grid item xs={12} sm={12} md={10} lg={8}>
                  <Card elevation={1} style={{ padding: "32px 24px" }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      direction="column"
                    >
                      <Grid item xs={3}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          <img
                            src={logoCeybaTransp}
                            alt="ceyba-logo"
                            style={{ width: 128, margin: 12 }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={8}>
                        <div>
                          <MDTypography
                            mt={3}
                            mb={2}
                            px={6}
                            color="info"
                            fontSize="2rem"
                            component="h2"
                            gutterBottom
                          >
                            WELCOME TO CEYBA
                          </MDTypography>
                          <MDTypography
                            color="text"
                            fontSize="1rem"
                            component="p"
                            gutterBottom
                            paragraph
                            align="justify"
                            variant="body1"
                            px={6}
                          >
                            This on-line tool, allows searching for corporations
                            registered with the “Puerto Rico Department of
                            State” The term “Corporation” refers to all types of
                            corporations, including but not limited to
                            Corporations, LLCs, partnership, etc, that are
                            required to register with the “Puerto Rico
                            Department of State”.
                          </MDTypography>
                          <MDTypography
                            color="text"
                            align="justify"
                            fontSize="1rem"
                            variant="body1"
                            component="p"
                            px={6}
                          >
                            Search results are presented in a format that allows
                            you to view general corporation information. To view
                            specific details click on the corporation’s name
                            from the search results.
                          </MDTypography>
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </MDBox>
            </Grid>
          </MDBox>
        </Hidden>
        <Hidden xsDown>
          <MDBox
            display={corpSelected ? "none" : "flex"}
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item xs={12} sm={12} md={10} lg={8}>
              <Card elevation={1} style={{ padding: "32px 24px" }}>
                <Grid
                  container
                  justifyContent="space-between"
                  direction="column"
                >
                  <Grid item xs={3}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <img
                        src={logoCeybaTransp}
                        alt="ceyba-logo"
                        style={{ width: 128, margin: 12 }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <div>
                      <MDTypography gutterBottom>
                        Welcome to Ceyba!
                      </MDTypography>
                      <MDTypography
                        color="text"
                        fontSize="1rem"
                        component="p"
                        gutterBottom
                        paragraph
                        align="justify"
                        variant="body1"
                      >
                        This on-line tool, allows searching for corporations
                        registered with the “Puerto Rico Department of State”
                        The term “Corporation” refers to all types of
                        corporations, including but not limited to Corporations,
                        LLCs, partnership, etc, that are required to register
                        with the “Puerto Rico Department of State”.
                      </MDTypography>
                      <MDTypography
                        color="text"
                        align="justify"
                        fontSize="1rem"
                        variant="body1"
                        component="p"
                      >
                        Search results are presented in a format that allows you
                        to view general corporation information. To view
                        specific details click on the corporation’s name from
                        the search results.
                      </MDTypography>
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </MDBox>
        </Hidden>
        <MDBox display={corpSelected ? "flex" : "none"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDTypography fontWeight="bold" fontSize="2rem" marginBottom={3}>
                {corpSelected.corp_name !== null
                  ? corpSelected.corp_name
                  : "Unknown"}
              </MDTypography>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox display="flex" direction="row">
                <MDTypography
                  fontWeight="bold"
                  fontSize="1rem"
                  color="info"
                  marginRight="1rem"
                >
                  Register Number:
                </MDTypography>
                <MDTypography fontWeight="regular" fontSize="1rem">
                  {corpSelected.corp_register_no !== null
                    ? corpSelected.corp_register_no
                    : "Unknown"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" direction="row">
                <MDTypography
                  fontWeight="bold"
                  fontSize="1rem"
                  color="info"
                  marginRight="1rem"
                >
                  Volume of Bussiness:
                </MDTypography>
                <MDTypography fontWeight="regular" fontSize="1rem">
                  {getRevenuesValue(corpSelected.corp_volume_business)}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" direction="row">
                <MDTypography
                  fontWeight="bold"
                  fontSize="1rem"
                  color="info"
                  marginRight="1rem"
                >
                  Formation Date:
                </MDTypography>
                <MDTypography fontWeight="regular" fontSize="1rem">
                  {corpSelected.corp_formation_date !== null
                    ? normalizeDate(corpSelected.corp_formation_date)
                    : "Unknown"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" direction="row">
                <MDTypography
                  fontWeight="bold"
                  fontSize="1rem"
                  color="info"
                  marginRight="1rem"
                >
                  Expiration Date:
                </MDTypography>
                <MDTypography fontWeight="regular" fontSize="1rem">
                  {corpSelected.corp_expiration_date !== null
                    ? corpSelected.corp_expiration_date
                    : "Unknown"}
                </MDTypography>
              </MDBox>
             <MDBox display="flex" direction="row">
                <MDTypography
                  fontWeight="bold"
                  fontSize="1rem"
                  color="info"
                  marginRight="1rem"
                >
                  Phone Number:
                </MDTypography>
                <MDTypography fontWeight="regular" fontSize="1rem">
                  {corpSelected.corp_designated_office_address_annual_filings_pdf !==
                    undefined &&
                  corpSelected.corp_designated_office_address_annual_filings_pdf !==
                    null &&
                  corpSelected.corp_designated_office_address_annual_filings_pdf !==
                    "[]"
                    ? JSON.parse(
                        corpSelected.corp_designated_office_address_annual_filings_pdf
                      ).map((address) => address.telephone)
                    : "Unknown"}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox display="flex" direction="row">
                <MDTypography
                  fontWeight="bold"
                  fontSize="1rem"
                  color="info"
                  marginRight="1rem"
                >
                  Category
                </MDTypography>
                <MDTypography fontWeight="regular" fontSize="1rem">
                  {corpSelected.corp_category !== null
                    ? corpSelected.corp_category
                    : "Unknown"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" direction="row">
                <MDTypography
                  fontWeight="bold"
                  fontSize="1rem"
                  color="info"
                  marginRight="1rem"
                >
                  Type:
                </MDTypography>
                <MDTypography fontWeight="regular" fontSize="1rem">
                  {corpSelected.corp_type !== null
                    ? corpSelected.corp_type
                    : "Unknown"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" direction="row">
                <MDTypography
                  fontWeight="bold"
                  fontSize="1rem"
                  color="info"
                  marginRight="1rem"
                >
                  Class:
                </MDTypography>
                <MDTypography fontWeight="regular" fontSize="1rem">
                  {corpSelected.corp_class}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" direction="row">
                <MDTypography
                  fontWeight="bold"
                  fontSize="1rem"
                  color="info"
                  marginRight="1rem"
                >
                  Jurisdiction:
                </MDTypography>
                <MDTypography fontWeight="regular" fontSize="1rem">
                  {corpSelected.corp_jurisdiction}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <MDBox display="flex" direction="row" mt={2}>
                <MDTypography
                  variant="button"
                  color="text"
                  fontWeight="regular"
                >
                  <MDTypography
                    display="inline"
                    variant="body2"
                    verticalAlign="middle"
                    mr={2}
                  >
                    <Icon
                      fontSize="small"
                      sx={{ color: ({ palette: { info } }) => info.main }}
                    >
                      open_in_new
                    </Icon>
                  </MDTypography>
                </MDTypography>
                <Link
                  href={corpSelected.corp_url_corporation_official_filing_web}
                  referrerPolicy="no-referrer"
                  target="_blank"
                >
                  <MDTypography fontSize="1rem">
                    Visit official website
                  </MDTypography>
                </Link>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {/* Annual Report Data */}
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{ display: corpSelected ? "block" : "none" }}
        >
          <MDBox mb={2} mt={8}>
            <ComplexCardPurpose
              icon={<CategoryIcon />}
              purposeInfo={
                corpSelected.corp_purpose !== null
                  ? corpSelected.corp_purpose
                  : "No records on file"
              }
              updateInfo={{
                color: "info",
                descriptionUpdate: "Last Update",
                descriptionTime: "Dec. 2021",
              }}
            />
          </MDBox>
        </Grid>
        <MDBox py={3}>
          <Grid
            container
            py={3}
            spacing={3}
            style={{ display: corpSelected ? "flex" : "none" }}
          >
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon={<MonetizationOnIcon />}
                  title="TOTAL ASSETS"
                  count={
                    corpSelected.corp_total_assets !== null
                      ? formatter.format(corpSelected.corp_total_assets)
                      : "--"
                  }
                  percentage={{
                    color: "info",
                    amount: "Last Update",
                    label: "Dec. 2021",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon={<MonetizationOnIcon />}
                  title="EQUITY"
                  count={
                    corpSelected.corp_equity !== null
                      ? formatter.format(corpSelected.corp_equity)
                      : "--"
                  }
                  percentage={{
                    color: "info",
                    amount: "Last Update",
                    label: "Dec. 2021",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon={<MonetizationOnIcon />}
                  title="PROPERTY & EQUIPMENT"
                  count={
                    corpSelected.corp_property_equipment !== null
                      ? formatter.format(corpSelected.corp_property_equipment)
                      : "--"
                  }
                  percentage={{
                    color: "info",
                    amount: "Last Update",
                    label: "Dec. 2021",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon={<MonetizationOnIcon />}
                  title="CURRENT LIABILITIES"
                  count={
                    corpSelected.corp_current_liabilities !== null
                      ? formatter.format(corpSelected.corp_current_liabilities)
                      : "--"
                  }
                  percentage={{
                    color: "info",
                    amount: "Last Update",
                    label: "Dec. 2021",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
          <Grid
            container
            py={3}
            spacing={3}
            style={{ display: corpSelected ? "flex" : "none" }}
          >
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon={<MonetizationOnIcon />}
                  title="CURRENT ASSETS"
                  color="info"
                  count={
                    corpSelected.corp_total_assets !== null
                      ? formatter.format(corpSelected.corp_current_assets)
                      : "--"
                  }
                  percentage={{
                    color: "info",
                    amount: "Last Update",
                    label: "Dec. 2021",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon={<MonetizationOnIcon />}
                  title="TOTAL EQUITY"
                  color="info"
                  count={
                    corpSelected.corp_equity !== null
                      ? formatter.format(corpSelected.corp_total_equity)
                      : "--"
                  }
                  percentage={{
                    color: "info",
                    amount: "Last Update",
                    label: "Dec. 2021",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon={<MonetizationOnIcon />}
                  title="OTHER ASSETS"
                  color="info"
                  count={
                    corpSelected.corp_property_equipment !== null
                      ? formatter.format(corpSelected.corp_other_assets)
                      : "--"
                  }
                  percentage={{
                    color: "info",
                    amount: "Last Update",
                    label: "Dec. 2021",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon={<MonetizationOnIcon />}
                  title="LONG-TERM LIABILITIES"
                  color="info"
                  count={
                    corpSelected.corp_current_liabilities !== null
                      ? formatter.format(corpSelected.corp_longterm_liabilities)
                      : "--"
                  }
                  percentage={{
                    color: "info",
                    amount: "Last Update",
                    label: "Dec. 2021",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
          {/* <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="info"
                    title="Assets"
                    description="Performance"
                    date=""
                    chart={reportsBarChartData}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="success"
                    title="daily sales"
                    description={
                      <>
                        (<strong>+15%</strong>) increase in today sales.
                      </>
                    }
                    date="updated 4 min ago"
                    chart={["50", "100"]}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="dark"
                    title="completed tasks"
                    description="Last Campaign Performance"
                    date="just updated"
                    chart={["50", "100"]}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox> */}

          <MDBox>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={6}
                lg={8}
                style={{
                  display: corpSelected ? "block" : "none",
                  margin: "24px 0px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ display: corpSelected ? "block" : "none" }}
                >
                  <MDBox mb={2} mt={0}>
                    <InfoCard
                      title="Authorized Person"
                      color="info"
                      dataJson={
                        corpSelected.corp_auth_person_annual_filings_pdf !==
                          null &&
                        corpSelected.corp_auth_person_annual_filings_pdf !==
                          "[]"
                          ? corpSelected.corp_auth_person_annual_filings_pdf
                          : null
                      }
                    />
                  </MDBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ display: corpSelected ? "block" : "none" }}
                >
                  <MDBox mb={2} mt={6}>
                    <InfoCard
                      title="Resident Agent"
                      color="info"
                      dataJson={
                        corpSelected.corp_resident_agent_annual_filings_pdf !==
                          null &&
                        corpSelected.corp_resident_agent_annual_filings_pdf !==
                          "[]"
                          ? corpSelected.corp_resident_agent_annual_filings_pdf
                          : JSON.stringify({
                              name: corpSelected.corp_resident_agent_name,
                              street_address:
                                corpSelected.corp_resident_agent_street_address,
                              mailing_address:
                                corpSelected.corp_resident_agent_mailing_address,
                            })
                      }
                    />
                  </MDBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  mt={6}
                  style={{ display: corpSelected ? "block" : "none" }}
                >
                  <MDBox mb={2} mt={4}>
                    <InfoCard
                      title="Designated Office Address"
                      color="info"
                      dataJson={
                        corpSelected.corp_resident_agent_certificated_filings_pdf !==
                        null
                          ? corpSelected.corp_resident_agent_certificated_filings_pdf
                          : JSON.stringify({
                              street_address:
                                corpSelected.corp_designated_office_street_address,
                              mailing_address:
                                corpSelected.corp_designated_office_mailing_address,
                            })
                      }
                    />
                  </MDBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ display: corpSelected ? "block" : "none" }}
                >
                  <MDBox mb={2} mt={6}>
                    <InfoCard
                      title="Incorporators"
                      color="info"
                      dataJson={
                        corpSelected.corp_incorporators_general_info !== null
                          ? corpSelected.corp_incorporators_general_info
                          : null
                      }
                    />
                  </MDBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ display: corpSelected ? "block" : "none" }}
                >
                  <MDBox mb={2} mt={6}>
                    <InfoCard
                      title="Directors"
                      color="info"
                      dataJson={
                        corpSelected.corp_directors_certificated_filings_pdf !==
                        null
                          ? corpSelected.corp_directors_certificated_filings_pdf
                          : null
                      }
                    />
                  </MDBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  mt={6}
                  style={{ display: corpSelected ? "block" : "none" }}
                >
                  <MDBox mb={2} mt={4}>
                    <InfoCard
                      title="Officers"
                      color="info"
                      dataJson={
                        corpSelected.corp_officers_certificated_filings_pdf !==
                        null
                          ? corpSelected.corp_officers_certificated_filings_pdf
                          : corpSelected.corp_officers_general_info
                      }
                    />
                  </MDBox>
                </Grid>
                {/*
                <Card sx={{ marginTop: "4rem" }}>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={1}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDBox display="flex" flexGrow={1}>
                      <MDTypography variant="h6" color="white" width="100%">
                        Contact Information
                      </MDTypography>
                      <MDBox color="text" px={1} display={rows.length >= 1 ? "flex" : "none"}>
                        <Icon
                          sx={{ cursor: "pointer", fontWeight: "bold" }}
                          fontSize="small"
                          onClick={openMenu}
                        >
                          more_vert
                        </Icon>
                      </MDBox>
                    </MDBox>
                    <span>{renderMenu}</span>
                  </MDBox>
                  <MDBox pt={3}>
                    {rows.length <= 0 ? (
                      <MDTypography
                        variant="h6"
                        color="text"
                        width="100%"
                        align="center"
                        padding={4}
                      >
                        No records on file
                      </MDTypography>
                    ) : (
                      <DataTableResults
                        table={{ columns, rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    )}
                  </MDBox>
                </Card>
                */}
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                {corpSelected && <AnnualFilingsView />}
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </div>
  );
}

export default Dashboard;
