import bcrypt from "bcryptjs";
import { fbAuth } from "../firebase/firebase-config";

class Auth {
  constructor() {
    this.authenticated = false;
    this.salt = null;
    this.hash = null;
    this.auth_token = null;
    this.auth = false;
    this.response = null;
  }

  /**
   * It sends a POST request to the server with the user's email and password, and if the server
   * returns a response with the auth property set to true, it sets the auth_token and _ceyba_auth
   * properties in localStorage.
   * @param userEmail - the email of the user
   * @param userPassword - the password that the user entered in the login form
   * @param cb - callback function
   * @returns The response from the server.
   */
  async login(userEmail, userPassword, cb) {
    // secure email && password:
    this.salt = bcrypt.genSaltSync(10);
    this.hash = bcrypt.hashSync(userPassword, this.salt);

    const dataBodyRequest = JSON.stringify({
      email: userEmail,
      password: userPassword,
    });

    await fetch("https://anlisoft.info/api/login", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: dataBodyRequest,
    })
      .then((response) => response.json())
      .then((res) => {
        this.authenticated = res.auth;
        localStorage.setItem("auth_token", res.auth_token);
        localStorage.setItem("_ceyba_auth", res.auth);
        this.response = res;
        if (res.auth) {
          cb();
        }
        /**
         * If the user is logged in, log them out and remove their auth token from local storage.
         */
      })
      .catch(() => {
        this.authenticated = false;
        localStorage.removeItem("auth_token");
        localStorage.removeItem("_ceyba_auth");
      });
    return this.response;
  }

  /**
   * If the user is logged in, log them out and remove their auth token from local storage.
   */
  logout() {
    this.authenticated = false;
    localStorage.removeItem("auth_token");
    localStorage.removeItem("_ceyba_auth");
    fbAuth.signOut();
  }

  /**
   * If the localStorage item "_ceyba_auth" exists, then the user is authenticated.
   * @returns The value of the variable authenticated.
   */
  isAuthenticated() {
    const ceybaAuth = JSON.parse(localStorage.getItem("_ceyba_auth"));
    if (ceybaAuth) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
    return this.authenticated;
  }
}
export default new Auth();
