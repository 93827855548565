/* eslint-disable react/prop-types */
import React, { useState, useContext } from "react";
import { useTheme } from "@mui/material/styles";
//import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import MDButton from "components/MDButton";
import { CSVLink } from "react-csv";

// data fields
import {
  AdvancedSearchContext,
  csvHeaders,
} from "context/AdvancedSearchContext";
import MDBox from "components/MDBox";
import { FormControl, Grid, InputLabel } from "@mui/material";
//import MDTypography from "components/MDTypography";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      minHeight: ITEM_HEIGHT,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};

function getStyles(field, fieldSelected, theme) {
  return {
    fontWeight:
      fieldSelected.indexOf(field) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AdvancedFieldCSVExport({ onCloseModal }) {
  const theme = useTheme();
  const [fieldListSelected, setFieldListSelected] = useState([]);
  const { dataAdvancedCSV } = useContext(AdvancedSearchContext);
  const [errorFieldCSV, setErrorFieldCSV] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFieldListSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleClearSelection = () => {
    setFieldListSelected([]);
  };

  const handleSelectAll = () => {
    setFieldListSelected(csvHeaders);
  };

  const handleGenerateCSV = () => {
    if (fieldListSelected.length >= 1) {
      setErrorFieldCSV(false);
      return true;
    }
    setErrorFieldCSV(true);
    return false;
  };

  const handleCloseModal = () => {
    onCloseModal();
  };

  return (
    <>
      <MDBox>
        <FormControl fullWidth style={{ marginTop: 16 }}>
          <InputLabel id="csv-select-label">Fields</InputLabel>
          <Select
            labelId="csv-select-label"
            id="csv-select"
            multiple
            placeholder="Fields"
            label="Fields"
            error={errorFieldCSV}
            value={fieldListSelected}
            onChange={handleChange}
            renderValue={(selected) => (
              <MDBox
                sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, padding: 2 }}
              >
                {selected.map((value) => (
                  <Chip key={value.key} label={value.label} color="info" />
                ))}
              </MDBox>
            )}
            MenuProps={MenuProps}
            sx={{
              minHeight: 48,
              color: "info",
              width: "100%",
              inputLabelRoot: "info",
            }}
          >
            {csvHeaders.map((field) => (
              <MenuItem
                key={field.key}
                value={field}
                style={getStyles(field.label, fieldListSelected, theme)}
              >
                {field.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MDBox>
      <MDBox>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          mt={0.5}
        >
          <Grid item xs="auto">
            <MDButton
              color="info"
              variant="gradient"
              onClick={handleSelectAll}
              style={{ whiteSpace: "nowrap" }}
            >
              select all fields
            </MDButton>
          </Grid>
          <Grid item xs="auto">
            <MDButton
              color="info"
              variant="gradient"
              onClick={handleClearSelection}
              style={{ whiteSpace: "nowrap" }}
            >
              clear selection
            </MDButton>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          mt={6}
        >
          <Grid item xs="auto">
            <MDBox>
              <CSVLink
                headers={fieldListSelected}
                data={dataAdvancedCSV}
                disabled={fieldListSelected.lenght <= 0}
                filename={"ceyba_csv_"
                  .concat(new Date().toLocaleDateString("en-US"))
                  .concat(".csv")}
                onClick={handleGenerateCSV}
              >
                <MDButton
                  color="success"
                  variant="gradient"
                  sx={{ width: "10rem", whiteSpace: "nowrap" }}
                >
                  generate csv
                </MDButton>
              </CSVLink>
            </MDBox>
          </Grid>
          <Grid item xs="auto">
            <MDButton
              color="info"
              variant="gradient"
              onClick={handleCloseModal}
              sx={{ width: "10rem", whiteSpace: "nowrap" }}
            >
              close
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
