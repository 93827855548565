import dotenv from "dotenv";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";
import { DashboardProvider } from "context/DashboardContext";
import { AdvancedSearchProvider } from "context/AdvancedSearchContext";
import { LoginProvider } from "context/LoginContext";

dotenv.config({ path: "./.env" });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LoginProvider>
    <BrowserRouter>
      <DashboardProvider>
        <AdvancedSearchProvider>
          <MaterialUIControllerProvider>
            <App />
          </MaterialUIControllerProvider>
        </AdvancedSearchProvider>
      </DashboardProvider>
    </BrowserRouter>
  </LoginProvider>
);
