import { useState, useEffect, useContext } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/material/Stack";

// Material Dashboard
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard
import Breadcrumbs from "components_base/Breadcrumbs";
import NotificationItem from "components_base/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "components_base/Navbars/DashboardNavbar/styles";

// Material Dashboard React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { DashboardContext } from "context/DashboardContext";
import { AdvancedSearchContext } from "context/AdvancedSearchContext";

// API service
import { searchCorporationByName } from "api/apiService";
import MDButton from "components/MDButton";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const { setCorpList, setSearchLoading } = useContext(DashboardContext);
  const { setAdvanceCorpList } = useContext(AdvancedSearchContext);
  const [openMenu, setOpenMenu] = useState(false);
  const [corpNameSearch, setCorpNameSearch] = useState("");
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages (coming soon)" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const handleOnChangeInput = (e) => {
    setCorpNameSearch(e.target.value);
  };

  const handleOnClickSearchButton = async (e) => {
    e.preventDefault();
    setSearchLoading(true);

    try {
      // clear all values
      setCorpList([]);
      setAdvanceCorpList([]);
      // go to quick search result table
      navigate("/quick-search");
      // request data

      const data = await searchCorporationByName(corpNameSearch);
      const cookieCorpList = JSON.stringify(data);
      localStorage.setItem("corporations_list", cookieCorpList);
      // Set corporation list dashboard
      setCorpList(data);

      setSearchLoading(false);
    } catch (error) {
      setCorpList([]);
      setSearchLoading(false);
    }
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox pr={1}>
              <form onSubmit={(e) => handleOnClickSearchButton(e)}>
                <Stack direction="row" spacing={2}>
                  <MDInput
                    label="Quick Search..."
                    value={corpNameSearch}
                    onChange={(e) => handleOnChangeInput(e)}
                  />
                  <MDButton
                    color="info"
                    variant="gradient"
                    size="medium"
                    type="submit"
                    endIcon={
                      <Icon fontSize="medium">
                        <SearchIcon />
                      </Icon>
                    }
                    onClick={(e) => handleOnClickSearchButton(e)}
                    style={{ color: "#fff" }}
                  >
                    Search
                  </MDButton>
                </Stack>
              </form>
            </MDBox>

            <MDBox color={light ? "white" : "inherit"}>
              <Link to="/dashboard">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>dashboard</Icon>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
