import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import defaultColumns from "../layouts/advance_search/components/AdvancedSearchTable/columns";

export const AdvancedSearchContext = createContext();

// set default columns for advanced search: initial state
export const defaultCfgTableAdvancedSearch = defaultColumns;

// set initial state for advanced search
export const advancedSearchFilterOptions = [
  {
    by_ta: "",
    by_eq: "",
    by_pe: "",
    by_cl: "",
    by_ctg: "",
    by_type: "",
    by_date: "",
    by_jur: "",
    by_rev: "",
    by_keyword: "",
    by_min_ta: "",
    by_max_ta: "",
    by_min_eq: "",
    by_max_eq: "",
    by_min_pe: "",
    by_max_pe: "",
    by_min_cl: "",
    by_max_cl: "",
  },
];

// set csv defaul theaders initial state
export const csvHeaders = [
  { label: "Corporation Name", key: "corp_name" },
  { label: "Class", key: "corp_class" },
  { label: "Revenues", key: "corp_volume_business" },
  { label: "Type", key: "corp_type" },
  {
    label: "Officers Emails",
    key: "corp_officers_certificated_filings_pdf",
  },
  {
    label: "Directors Emails",
    key: "corp_directors_certificated_filings_pdf",
  },
  {
    label: "Resident Agent Contact Info",
    key: "corp_resident_agent_certificated_filings_pdf",
  },
  {
    label: "Telephone",
    key: "corp_resident_agent_certificated_filings_pdf",
  },
  { label: "Type", key: "corp_type" },
  { label: "Jurisdiction", key: "corp_jurisdiction" },
  { label: "Formation Date", key: "corp_formation_date" },
  { label: "Total Assets", key: "corp_total_assets" },
  { label: "Equity", key: "corp_equity" },
  { label: "Property & Equipment", key: "corp_property_equipment" },
  { label: "Current Liabilities", key: "corp_current_liabilities" },
];

export const AdvancedSearchProvider = ({ children }) => {
  const [userOptionsSelect, setUserOptionsSelect] = useState([
    {
      by_ta: "none",
      by_eq: "none",
      by_pe: "none",
      by_cl: "none",
      by_ctg: "none",
      by_type: "none",
      by_date: "",
      by_jur: "none",
      by_rev: "none",
      by_keyword: "",
    },
  ]);
  const [customRangeValues, setCustomRangeValues] = useState([
    {
      by_min_ta: "",
      by_max_ta: "",
      by_min_eq: "",
      by_max_eq: "",
      by_min_pe: "",
      by_max_pe: "",
      by_min_cl: "",
      by_max_cl: "",
    },
  ]);
  const [columnOrder, setColumnOrder] = useState([
    { id: "Corporation Name", label: "corp_name" },
    { id: "Class", label: "corp_class" },
    { id: "Revenues", label: "corp_volume_business" },
    { id: "Type", label: "corp_type" },
    { id: "Jurisdiction", label: "corp_jurisdiction" },
    { id: "Formation Date", label: "corp_formation_date" },
    { id: "Total Assets", label: "corp_total_assets" },
    { id: "Equity", label: "corp_equity" },
    { id: "Officers Emails", label: "corp_officers_certificated_filings_pdf" },
    {
      id: "Directors Emails",
      label: "corp_directors_certificated_filings_pdf",
    },
    {
      id: "Telephone",
      label: "corp_designated_office_address_annual_filings_pdf",
    },
    {
      id: "Resident Agent Contact Info",
      label: "corp_resident_agent_certificated_filings_pdf",
    },
    { id: "Property & Equipment", label: "corp_property_equipment" },
    { id: "Current Liabilities", label: "corp_current_liabilities" },
  ]);
  const [corpAdvanceList, setAdvanceCorpList] = useState([]);
  const [isAdvanceSearchLoading, setAdvanceSearchLoading] = useState(false);
  const [dataAdvancedCSV, setAdvancedDataCSV] = useState([]);
  // set list of fileds to export data to csv
  const [fieldListToExport, setFieldListToExport] = useState([]);
  // set state config for advanced table
  const [configAvancedTable, setConfigAvancedTable] = useState([]);

  return (
    <AdvancedSearchContext.Provider
      value={{
        userOptionsSelect,
        setUserOptionsSelect,
        corpAdvanceList,
        setAdvanceCorpList,
        isAdvanceSearchLoading,
        setAdvanceSearchLoading,
        dataAdvancedCSV,
        setAdvancedDataCSV,
        columnOrder,
        setColumnOrder,
        csvHeaders,
        fieldListToExport,
        setFieldListToExport,
        customRangeValues,
        setCustomRangeValues,
        configAvancedTable,
        setConfigAvancedTable,
      }}
    >
      {children}
    </AdvancedSearchContext.Provider>
  );
};

// Defaults props
AdvancedSearchProvider.defaultProps = {
  children: null,
};

// Typechecking props
AdvancedSearchProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.any),
};
