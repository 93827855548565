// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
/* import Icon from "@mui/material/Icon"; */

// Material Dashboard
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// context
import { parseJsonData, capitalizeStr } from "utils/util";

function InfoCard({ color, title, dataJson }) {
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="lg"
          display="flex"
          position="absolute"
          justifyContent="left"
          alignItems="center"
          minWidth="12rem"
          height="2.5rem"
          mt={-3}
          py={2}
          px={2}
        >
          <MDTypography variant="h6" color="white" fontSize="1rem">
            {title}
          </MDTypography>
        </MDBox>
        <MDBox mt={2} position="relative" width="100%">
          <MDBox textAlign="left" lineHeight={1.25} mt={2}>
            {dataJson !== null && dataJson !== "[]" && !dataJson.includes("null") ? (
              <>
                {parseJsonData(dataJson).map((dataObj) => (
                  <MDBox display="flex" flexDirection="column">
                    {Object.keys(dataObj).map((key) => (
                      <MDBox>
                        <MDBox display="flex" direction="row">
                          <MDTypography
                            fontWeight="bold"
                            variant="button"
                            fontSize="1rem"
                            color="info"
                            marginRight="1rem"
                          >
                            {capitalizeStr(key)}
                          </MDTypography>
                          <MDTypography variant="button" align="left">
                            {dataObj[key].includes("[]") ||
                            dataObj[key].includes("null") ||
                            dataObj[key].includes("On File") ? (
                              <MDTypography
                                variant="button"
                                color="error"
                                align="left"
                              >
                                No records on file
                              </MDTypography>
                            ) : (
                              dataObj[key]
                            )}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    ))}
                    <Divider />
                  </MDBox>
                ))}
              </>
            ) : (
              <MDBox>
                <MDTypography variant="button" color="error" align="left">
                  No records on file
                </MDTypography>
                <Divider />
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
InfoCard.defaultProps = {
  color: "info",
  title: "",
  dataJson: "[]",
};

// Typechecking props for the ComplexStatisticsCard
InfoCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string,
  dataJson: PropTypes.string,
};

export default InfoCard;
