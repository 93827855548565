/* Creating an array of objects. */
export const optionSelect = [
  { key: "none", label: "None" },
  { key: "less_500k", label: "Less Than 500K $" },
  { key: "less_1M", label: "Less Than 1M $" },
  { key: "less_2M", label: "Less Than 2M $" },
  { key: "greater_3M", label: "Greater Than 3M $" },
];

export const optionSelectJurisdiction = [
  { key: "none", label: "None" },
  { key: "domestic", label: "Domestic" },
  { key: "foreign_non_us", label: "Foreign - NON US" },
  { key: "foreign", label: "Foreign" },
  { key: "unknown", label: "Unknown" },
];

export const optionSelectRevenues = [
  { key: "none", label: "None" },
  { key: "exceed_revenue", label: "Exceeds three million dollars" },
  { key: "no_revenue", label: "No exceeds three million dollars" },
];

export const optionSelectClass = [
  "Youth Cooperative",
  "Corporation",
  "Consumer Cooperative",
  "Close Corporation",
  "Limited Liability Company",
  "Savings & Credit Cooperative",
  "Workers Cooperative",
  "Unknown",
  "Professional Corporation",
  "Low Profit LLC",
  "Public Benefit Corporation",
  "Municipal Enterprise",
  "Limited Liability Partnership",
  "Insurance Company",
  "International Banking Center",
  "Special Employee Owned Corporation",
  "Hybrid Cooperative",
  "Sponsored By Municipal Corporation",
  "International Financial Entity",
  "Bank",
  "Electric Cooperative",
  "Special Corporation for Municipal Development",
  "Trust",
];

export const monthNames = [
  "all",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const optionSelectType = [
  { key: "none", label: "None" },
  { key: "non_profit", label: "Non-Profit" },
  { key: "for_profit", label: "For Profit" },
  { key: "unknown", label: "Unknown" },
];

export const optionSelectCategory = [
  "Economic, Social and Community Development",
  "Religious Services",
  "Donation",
  "(Other Services) Educational, Social and Community...",
  "Art and Culture",
  "(Other Services) Home Development for the Elderly",
  "(Other Services) Concientizar a las personas sobre...",
  "(Other Services) Indole Civico, Humanitario",
  "International Activities",
  "(Other Services) Servicios Sociales, Educativos y ...",
  "Institutional Services",
  "Recreation and Sports Services",
  "Social Services",
  "Educational and Research Services",
  "(Other Services) organizar cert.de belleza en dond...",
  "(Other Services) Educacion y Colocacion de Empleos...",
  "(Other Services) Educational and Community Support",
  "(Other Services) entretenimiento",
  "Health Services",
  "(Other Services) Salud, vivienda y otros",
  "(Other Services) Administrative Services",
  "(Other Services) Credit Counseling",
  "(Other Services) Profesionales Variados.",
  "(Other Services) Servicios educativos y psicologic...",
  "(Other Services) Administrar servicios relacionado...",
  "Housing Services",
  "(Other Services) divercion",
  "(Other Services) OTROS SERVICIOS",
  "Legal Services and Right Defense",
  "(Other Services) AGRICOLA",
  "(Other Services) SERVICIO DE ALIMENTOS",
  "Environmental Services",
  "(Other Services) ASOC. DE RESIDENTES",
  "(Other Services) Rehabilitacion",
  "(Other Services) Desarrollo Profesional y Espiritu...",
  "(Other Services) Albergue de animales",
  "(Other Services) Consultoria",
  "(Other Services) Servicios Educativos y Sociales",
  "(Other Services) Asociacion de Residentes",
  "(Other Services) COMMERCIAL ALLIANCE",
  "(Other Services) MANTENIMIENTO",
  "(Other Services) Not-For-Profit Membership Associa...",
  "(Other Services) MANTENIMIENTO PISCINAS",
  "(Other Services) CUIDO DE NINOS",
  "(Other Services) Baile",
  "(Other Services) Servicios de Emergencias",
  "(Other Services) Servicio de Hogar Sustituto",
  "(Other Services) TO PROVIDE FINANCIAL ASSISTANCE",
  "(Other Services) Human Resources Consultants",
  "(Other Services) Actividades recreativas y cultura...",
  "(Other Services) Noticias de forma cibernetica",
  "(Other Services)",
  "(Other Services) LA CAPACITACION Y CERTIFICACION D...",
  "(Other Services) Prof. contabilidad-admtcion-event...",
  "(Other Services) LA CAPACITACION Y CERTIFICACION D...",
  "(Other Services) CUIDO NINOS",
  "(Other Services) SERVICIO DE EMERGENCIA Y DESASTRE",
  "(Other Services) SERVICIOS EDUCATIVOS",
  "(Other Services) Iglesia-Escuela",
  "(Other Services) ASOC. DE CONDOMINES",
  "(Other Services) Community Services",
  "(Other Services) Orientar y coordinar servicios de...",
  "(Other Services) desarrollo comunitario y servicio",
  "(Other Services) Union Obrera",
  "(Other Services) Partido Politico",
  "(Other Services) SERVICIOS SOCIALES INTEGRAL, ORIE...",
  "(Other Services) Iniciativas Educativas y Ambienta...",
  "(Other Services) Produccion y Promocion de la Narr...",
  "(Other Services) CONTABILIDAD Y CONTRIBUTIVO",
  "(Other Services) Grupo Motociclista",
  "(Other Services) EDUCATIVO Y SOCIAL",
  "(Other Services) Promover Servicios a adultos y ad...",
  "(Other Services) Trabajo Comunitario",
  "(Other Services) Servicios de Administracion y Con...",
  "(Other Services) educativo y comunitario",
  "(Other Services) PROVEER SERVICIOS DE AGUA A LA CO...",
  "(Other Services) Water Utility Services",
  "(Other Services) Puerto Rico",
  "(Other Services) AGUA POTABLE",
  "(Other Services) ACUEDUCTO DE AGUA POTABLE PARA LA...",
  "(Other Services) Acueducto Rural (Servicio de Agua...",
  "(Other Services) Acueducto Comunitario",
  "(Other Services) Servicio de acueducto Sector El P...",
  "(Other Services) sustentar el sistema de agua pota...",
  "(Other Services) Acueducto Rural/Servicio de agua ...",
  "(Other Services) Provide water to the community",
  "(Other Services) Servicio de Agua",
  "(Other Services) Sistema de Acueducto",
  "(Other Services) Water services",
  "(Other Services) SERVICIOS AGUA POTABLE",
  "(Other Services) Agua",
  "(Other Services) Administracion de Acueducto",
  "(Other Services) FISCAL AGENT",
  "(Other Services) Servicios de Agua a la Comunidad",
  "(Other Services) SIN FINES DE LUCRO",
  "(Other Services) Salud Mental y relacionados",
  "(Other Services) SUPLIDOR DE AGUA POTABLE A LA COM...",
  "(Other Services) Tutela",
  "(Other Services) ACUEDUCTO RURAL",
  "(Other Services) EDUCACION",
  "(Other Services) EMERGENCIAS",
  "(Other Services) Purchase utilities & Provide serv...",
  "(Other Services) Boats",
  "(Other Services) Servicios de Cuidado a Envejecien...",
  "(Other Services) Animal Refuge",
  "(Other Services) limpieza",
  "(Other Services) Se provee varios servicios: socia...",
  "(Other Services) Estudiantes Voluntarios en Servic...",
  "(Other Services) Health and Paralegal Services",
  "(Other Services) SERV. EDUC. Y DE INVEST.- DES. EC...",
  "(Other Services) SERVICIO MAQUINARIA AGRICULTORES",
  "(Other Services) Servicios de Orientacion y Educat...",
  "(Other Services) Agrupacion",
  "(Other Services) Agrupacion Musical",
  "(Other Services) Bomberos y Emergencias Medicas",
  "(Other Services) ND Relief",
  "Fraternal",
  "(Other Services) Taxis",
  "(Other Services) CREDIT UNION",
  "(Other Services) Club de Tiro",
  "(Other Services) Business Association",
  "(Other Services) servicios prestado",
  "(Other Services) Eventos",
  "(Other Services) FILANTROPIA Y ATENDER NINOS CON C...",
  "(Other Services) Political Education",
  "(Other Services) Desarrollo espiritual e integral",
  "(Other Services) Albergue de ninos",
  "(Other Services) Mental Health Prevention and Inte...",
  "(Other Services) Missionary association",
  "(Other Services) TRANSLADO DE CADAVERES",
  "(Other Services) CLUB DE AUTOS",
  "(Other Services) MUSICA",
  "(Other Services) Servicios de Vigilancia r Informa...",
  "(Other Services) Servicios de Desarrollo Profesion...",
  "(Other Services) Enlaces comunitarios",
  "(Other Services) Educativo, Arte, Cultura y Recrea...",
  "(Other Services) Establecer y mantener Centros de ...",
  "(Other Services) SERVICIOS DE PREVENCION DE DROGAS...",
  "(Other Services) DESARROLLO PASTORAL",
  "(Other Services) Brindar ayuda ante un desastre",
  "(Other Services) DEFENSA DERECHOS DE ACCESO A LA S...",
  "(Other Services) EDUCACION, DERECHOS DE LOS ANIMAL...",
  "(Other Services) Social, Donativo y Educacion",
  "(Other Services) Servicios Sociales y Educativos",
  "(Other Services) Natural Disaster Relief",
  "(Other Services) Servicios Motivacionales y ayuda ...",
  "(Other Services) Rescate y rehabilitacion de anima...",
  "(Other Services) Servicios religiosos, desarrollo ...",
  "(Other Services) Servicios al necesitado",
  "(Other Services) For the moment, we have a service...",
  "(Other Services) Religiosa",
  "(Other Services) audit circulation numbers of our ...",
  "(Other Services) Ministerio Evangelistico",
  "(Other Services) Suplir a la necesitado",
  "(Other Services) cuido de envejecientes",
  "(Other Services) Servicios de ayuda profesional",
  "(Other Services) SER.REL.,SERV. DE SALUD, SER. REC...",
  "(Other Services) Radioaficionados",
  "(Other Services) Servicios profesionales y",
  "(Other Services) Bienestar animal",
  "(Other Services) Ayuda Familiares de Alcoholicos",
  "(Other Services) Interpretacion en Senas para Sord...",
  "(Other Services) Ventas",
  "(Other Services) organizacion de camioneros",
  "(Other Services) BASE MANTENIMIENTO LANCHAS",
  "(Other Services) public benefit, non-profit organi...",
  "(Other Services) porton de acceso",
  "(Other Services) Comunitaria y educativa",
  "(Other Services) Defenza del Libre acceso y Protec...",
  "(Other Services) ASOCIACION RESIDENTES",
  "(Other Services) Productos de primera necesidad, s...",
  "(Other Services) SINDICALES",
  "(Other Services) Al servicio de nuestra comunidad ...",
  "(Other Services) Sevicios a la comunidad",
  "(Other Services) ALTRUISMO",
  "(Other Services) Asociacion de urbanizacion",
  "(Other Services) Library",
  "(Other Services) Asociacion de Propietarios",
  "(Other Services) Servicios de Salud",
  "(Other Services) Propositos Educativos y Caritativ...",
  "(Other Services) Union",
  "(Other Services) Servicio",
  "(Other Services) Actividades adultos 3ra edad",
  "(Other Services) Apoyo y Orientacion",
  "(Other Services) Brindar servicios a la comunidad",
  "(Other Services) Cuido Pre Escolar",
  "(Other Services) Arte, Salud, Bienestar",
  "Servicios Sociales",
  "(Other Services) Charity",
  "(Other Services) Ayuda a los veteranos",
  "Veteran Services",
  "(Other Services) Ayuda a veteranos y sus familias",
  "(Other Services) Consultoria Gerencial",
  "(Other Services) ASOCIACION DE VETERANOS",
  "(Other Services) CLUB SOCIAL",
  "(Other Services) Organizacion de Almuerzo entre Am...",
  "(Other Services) rescate de animales",
  "(Other Services) POLITICAL",
  "(Other Services) control de acceso",
  "(Other Services) ASOCIACION DE MOTELES DE PR",
  "(Other Services) Obras",
  "(Other Services) PROFESIONAL",
  "(Other Services) EDUCACION CONTINUADA",
  "(Other Services) bailadores",
  "(Other Services) Servicio de bizcochos",
  "(Other Services) ACCESORIOS",
  "(Other Services) Mantenimiento de areas verdes",
  "(Other Services) Junta de Residentes",
  "(Other Services) Centro de Cuido para Personas de ...",
  "(Other Services) Car enthusiast",
  "(Other Services) Club",
  "(Other Services) Agricultura",
  "(Other Services) SOCIALES, SALUD,DONATIVOS",
  "(Other Services) Rescate",
  "(Other Services) Adopcion de perros y gatos",
  "(Other Services) Ayudar a los animales desamparado...",
  "(Other Services) Emisora de Radio Television Inter...",
  "(Other Services) Disaster and Emergency Relief Ser...",
  "(Other Services) Centro de Cuido de Envegecientes",
  "(Other Services) Cuido de Ancianos",
  "(Other Services) Adopcion Playa",
  "(Other Services) CONSEJERIA Y EVANGELISMO",
  "(Other Services) Asociacion Residente",
  "(Other Services) Servicios a Residentes",
  "(Other Services) Seguridad Publica",
  "(Other Services) Militares",
  "(Other Services) Assisting state insurance regulat...",
  "(Other Services) Workers Compensation Insurance In...",
  "(Other Services) GRUPO DE APOYO A PACIENTES Y FAMI...",
  "(Other Services) Asociacion de servicios a la nine...",
  "(Other Services) La organizacion y el desarrollo p...",
  "(Other Services) Ayuda Animales Sin Hogar",
  "(Other Services) Promover la industria de seguros ...",
  "(Other Services) SERVICIOS COMUNITARIOS",
  "(Other Services) SERVICIOS A LA MUJER",
  "(Other Services) Proveer Servicios Comunitarios",
  "(Other Services) Educativos y Orientacion",
  "(Other Services) educacion civica y politica",
  "(Other Services) Servicios de destrezas vida indep...",
  "(Other Services) entretaimento to kids",
  "(Other Services) Agricolas",
  "(Other Services) Promocion y Desarrollo de la Indu...",
  "(Other Services) Servicios de Cooperativa",
  "(Other Services) Cooperativa de Consumo",
  "(Other Services) ASOCIACION",
  "(Other Services) servicios filantropicos a la comu...",
  "(Other Services) Ayuda al Comerciante",
  "(Other Services) Centro de Servicios Comunitarios",
  "(Other Services) Servicios Comuniatrios",
  "(Other Services) Servicios a la Comunidad",
  "(Other Services) Cooperativa",
  "(Other Services) TRABAJOS TURISTICOS",
  "(Other Services) Filantropia",
  "(Other Services) Cementerio",
  "(Other Services) Billing",
  "(Other Services) Apoyo a la Policia,Bombero,D.C. e...",
  "(Other Services) agricultural",
  "(Other Services) Industry Association",
  "(Other Services) Propiciar y fomentar el desarroll...",
  "(Other Services) Consejeria",
  "(Other Services) Agricola - Pesca",
  "(Other Services) Desarrollo del Parque",
  "(Other Services) Servicios de Educacion y Prevenci...",
  "(Other Services) Arrendamiento comun de espacios d...",
  "(Other Services) Rescate de perros abandonados",
  "(Other Services) Brindar Ayuda",
  "(Other Services) Servicios religiosos y sociales",
  "(Other Services) Hogar de rehabilitacion para pers...",
  "(Other Services) Servicios voluntarios a pacientes...",
  "(Other Services) ayudas",
  "(Other Services) AYUDA A PACIENTES DE CANCER DE SE...",
  "(Other Services) Servicios de Educativos y de Salu...",
  "(Other Services) APOYO Y AYUDA A PERSONAS, ENTIDAD...",
  "(Other Services) Domestica",
  "(Other Services) a la poblacion",
  "(Other Services) NEGOCIAR CUBIERTAS DE SALUD",
  "(Other Services) Salud mental y psicologica",
  "(Other Services) Servicios de administracion",
  "(Other Services) Servicios Profesionales Venta de ...",
  "(Other Services) HOSPITAL COMUNITARIO",
  "(Other Services) noticias, farandula y orientacion",
  "(Other Services) Equipo atletico y ayuda comunitar...",
  "(Other Services) Handyman",
  "(Other Services) PROVEER SERVICIOS A PACIENTES DE ...",
  "(Other Services) Charitable Services",
  "(Other Services) CONCEDER DESEOS",
  "(Other Services) a la poblacion Puertoriquena",
  "(Other Services) predicar el evangelio a toda crea...",
  "(Other Services) Motivacion",
  "(Other Services) Union de Porteadores Publico",
  "(Other Services) Servicios a Veteranos, viudas y l...",
  "(Other Services) Servicios al Veterano",
  "(Other Services) Restauracion y preservacion autos...",
  "(Other Services) RECREACIONAL",
  "(Other Services) servicios indigentes",
  "(Other Services) Servicios Educativos y Relacionad...",
  "(Other Services) NO REUNIMO PARA LLEGAR A RESOLVER...",
  "(Other Services) Servir de herramienta para ayudar...",
  "(Other Services) Servicios de transporte",
  "(Other Services) Management and Financial Consulta...",
  "(Other Services) Proteccion de Dignatarios",
  "(Other Services) Ropa de ninos",
  "(Other Services) desarrollo turistico",
  "Servicios de Recreacion Y Deportes",
  "(Other Services) Iniciativa Comunitaria dirigida h...",
  "(Other Services) Ayuda a Sobrevivientes de Violenc...",
  "(Other Services) Operador Zona Libre #163",
  "(Other Services) CUIDO DE PERSONAS CON INCAPACIDAD...",
  "(Other Services) Grupo Comunitario",
  "(Other Services) Ofrecer Honores a militares a los...",
  "(Other Services) Servicios de Apoyo a Agencias de ...",
  "(Other Services) LOGIA",
  "(Other Services) Organizacion Obrera",
  "(Other Services) Cuido pacientes retardacion menta...",
  "(Other Services) SERVICIOS DE RAHABILITACION MENTA...",
  "(Other Services) ADMINISTRAR AREAS COMUNALES",
  "(Other Services) Reparacion de computadora y celul...",
  "(Other Services) ADM AREA RECREATIVA",
  "(Other Services) Disaster Response",
  "(Other Services) Daycare",
  "(Other Services) EDUCACION HUMANITARIA PARA LA PRO...",
  "(Other Services) SERVICIOS DE CUIDO EN EL HOGAR",
  "(Other Services) SERVICIOS MULTIPLES A VETERANOS Y...",
  "(Other Services) Preservation of Wildlife",
  "(Other Services) Sirviendo a Veteranos Familiares",
  "(Other Services) Asociacion Rotulistica",
  "(Other Services) SEGURIDA,EMERGENCIA MEDICA Y TURI...",
  "(Other Services) Accion Ciudadana",
  "(Other Services) Cuota Mantenimiento y otros servi...",
  "(Other Services) serv. a la comunidad",
  "(Other Services) Servicios Humanitarios",
  "(Other Services) AYUDA COMUNITARIA",
  "(Other Services) Ayudar al necesitado",
  "(Other Services) Servicios filantropicos",
  "(Other Services) Incubacion, aceleracion y promoci...",
  "(Other Services) Servicios psicologicos",
  "(Other Services) Desarrollo Profesional",
  "(Other Services) Ofrecer servicios gratuitos, tale...",
  "(Other Services) Construction",
  "(Other Services) temas de desarrollo y crecimiento",
  "(Other Services) Comunitario a demabulantes",
  "(Other Services) Asistencia social",
  "(Other Services) Ayuda social",
  "(Other Services) Adorar con mi voz y guitarra",
  "(Other Services) food",
  "(Other Services) CENTRO DE VETERANOS",
  "(Other Services) actividades juveniles",
  "(Other Services) ayuda al projimo",
  "(Other Services) Vease ",
  "propositos",
  "(Other Services) CANTO",
  "(Other Services) DESARROLLOS",
  "(Other Services) Asoc. Propietarios",
  "(Other Services) ASOC RESIDENTES",
  "(Other Services) Servicio a Deambulantes y comunid...",
  "(Other Services) Asociacion urbanizacion",
  "(Other Services) Administrar el mantenimiento de l...",
  "(Other Services) HOGAR DE CUIDO DE ANCIANOS",
  "(Other Services) EGIDA",
  "(Other Services) SERVICIOS DE CONSEJERIA Y COORDIN...",
  "(Other Services) Servicio Religioso",
  "(Other Services) PUBLIC SERVICES",
  "(Other Services) Charitable, Religious, and Educat...",
  "(Other Services) Information, travel, community se...",
  "(Other Services) organizacion caritativa",
  "(Other Services) Aftercare, Rehabilitation and ado...",
  "(Other Services) Voluntarios",
  "(Other Services) ORGANIZACION DE RESCATE VOLUNTARI...",
  "(Other Services) organizar eventos",
  "(Other Services) Belleza y Talento",
  "(Other Services) Animals",
  "(Other Services) SERVICIOS SINDICATO",
  "(Other Services) Red de mujeres profesionales que ...",
  "(Other Services) Certamen",
  "(Other Services) CERTAMEN DE BELLEZA",
  "(Other Services) Desarrollo de actividades general...",
  "(Other Services) Jueces de baloncesto",
  "(Other Services) Activism",
  "(Other Services) Deportivo",
  "(Other Services) CONTROL DE PLAGAS",
  "(Other Services) Organizacion Represemtativa",
  "(Other Services) voluntarios 24/7",
  "(Other Services) Advancement of women in all aviat...",
  "(Other Services) drop off servicio y materiales pa...",
  "(Other Services) Divulgacion de noticias",
  "(Other Services) Independenciapr",
  "(Other Services) Academic, Sports and Social Servi...",
  "(Other Services) Organizacion profesional y educat...",
  "(Other Services) Propiciar y fomentar el desarroll...",
  "(Other Services) Organizacion De Ayuda Social",
  "(Other Services) Communications Services",
  "(Other Services) Business League",
  "(Other Services) Food relief",
  "(Other Services) Education",
  "(Other Services) INVESTIGACION, EDUCACION Y APOYO ...",
  "(Other Services) Political Organization",
  "(Other Services) Club De Auto",
  "(Other Services) AYUDAR A LOS ADICTOS A LA COMIDA",
  "(Other Services) ASOCIACION PROFESIONAL DE CELEBRA...",
  "(Other Services) Comunicacion y Entretenimiento",
  "(Other Services) Servicios Apicolas",
  "(Other Services) Representacion y Apoyo",
  "(Other Services) Religiosos y sociales",
  "(Other Services) Gaming Industry",
  "(Other Services) PET RESCUE",
  "(Other Services) Local and National Political Part...",
  "(Other Services) Political Education Organization",
  "(Other Services) Professional Association",
  "(Other Services) ASOCIACION COMERCIANTES",
  "(Other Services) Servicios de salud, arte y deport...",
  "(Other Services) Actividades Caninas",
  "(Other Services) Responde a los afectados por un d...",
  "(Other Services) Assist economically and otherwise...",
  "(Other Services) Certamenes de Belleza",
  "(Other Services) Servicio a Veteranos Incapacitado...",
  "(Other Services) health advocacy and education",
  "(Other Services) FIRE RESCUE",
  "(Other Services) Educ, cultura, deportes y salud",
  "(Other Services) Apoyo y educacion",
  "(Other Services) Ver Anejo III",
  "(Other Services) Evento",
  "(Other Services) Dogs and pets",
  "(Other Services) Belleza",
  "(Other Services) SERVICIOS EDUCATIVOS Y DE INVESTI...",
  "(Other Services) serivisio voluntarios",
  "(Other Services) Comunicacion",
  "(Other Services) orientacion y organizacion de com...",
  "(Other Services) Profesional Culinaria",
  "(Other Services) EDUCATIVO",
  "(Other Services) Sport ju jitsu",
  "(Other Services) ORIENTACION, ENTRE OTROS",
  "(Other Services) (militar, comunitario, civico cul...",
  "(Other Services) Educational-Food Bank-Humanitaria...",
  "(Other Services) Asociacion Profesional",
  "(Other Services) eventos especiales",
  "(Other Services) Ayudar a las Comunidades",
  "(Other Services) SEVICIOS EDUCATIVOS Y DE INVESTIG...",
  "(Other Services) Transplant office administration ...",
  "(Other Services) Energy Services",
  "(Other Services) CIVICO CULTURAL",
  "(Other Services) Network Marketing",
  "(Other Services) Servicios individualizados",
  "(Other Services) RELIGIOUS,CHARITABLE,SCIENTIC, LI...",
  "(Other Services) Disaster relief X following Hurri...",
  "(Other Services) ALMACENAMIENTO Y MANTENIMIENTO DE...",
  "(Other Services) Servicios voluntarios de rescate ...",
  "(Other Services) Asociacion de cerrajeros",
  "(Other Services) Servicio de Vigilancia Meteorolog...",
  "(Other Services) Educativos",
  "(Other Services) CUIDADO DE NINOS",
  "(Other Services) Eduacion y registro de ganado lec...",
  "(Other Services) Alumni Association",
  "(Other Services) Estatus Politico",
  "(Other Services) CENTRO DE ENSENANZA",
  "(Other Services) RESCATE Y APOYO HUMANITARIO",
  "(Other Services) Emisora de Radio",
  "(Other Services) To promote the education of the m...",
  "(Other Services) Servicios Comunitarios, Salud y E...",
  "(Other Services) Evento social",
  "(Other Services) improving the service rendered to...",
  "(Other Services) grupo profesional postmasters",
  "(Other Services) ACTIVIDADES BENEFICAS",
  "(Other Services) Educativa, investigativa, social,...",
  "(Other Services) DESARROLLO PROFESIONAL EN EL AREA...",
  "(Other Services) Servisios tecnicos y profesionale...",
  "(Other Services) Servicios de rescate y rehabilita...",
  "(Other Services) Orientacion y Educacion",
  "(Other Services) Programa Exchange",
  "(Other Services) Trade Association- promoting como...",
  "(Other Services) Servicios de transportacion",
  "(Other Services) administracion y mantenimiento de...",
  "(Other Services) prevencion de incendio",
  "(Other Services) Interdisciplinarios",
  "(Other Services) Control de sobrepoblacion de anim...",
  "(Other Services) educacion sobre animales",
  "(Other Services) Liga Comercial",
  "(Other Services) Professional training and counsel...",
  "(Other Services) AGRIGULTURA, Y OTROS",
  "(Other Services) Actividades deportivas ,social, r...",
  "(Other Services) Community Based Organization",
  "(Other Services) Fishing club apparel",
  "(Other Services) servicios al turista",
  "(Other Services) Politico",
  "(Other Services) Levantar fondos y fomentar activi...",
  "(Other Services) VOLUNTARIOS PARA RESPUESTA DE EME...",
  "(Other Services) Desarrollo Multisectorial",
  "(Other Services) Adiestramientos",
  "(Other Services) Cultural y educativo",
  "(Other Services) en pro de impedidos de Puerto Ric...",
  "(Other Services) Administracion Duenos de Caballos...",
  "(Other Services) Servicios a sus miembros",
  "(Other Services) Motorcycle club",
  "(Other Services) Servicios profesionales",
  "(Other Services) Manejo de Emergencia, Busqueda y ...",
  "(Other Services) Rescate de Animales ABANDONADOS e...",
  "(Other Services) Promover y defender principios",
  "(Other Services) automotriz",
  "(Other Services) ORIENTACION",
  "(Other Services) Asociacion de empleados",
  "(Other Services) Asociacion Civica",
  "(Other Services) AUTOS ANTIGUOS Y CLASICOS",
  "(Other Services) PROMOCION Y EDUCACION AREA DE LA ...",
  "(Other Services) Centro de Actividades",
  "(Other Services) Servicios de seguridad",
  "(Other Services) (ASOCIACION DE RESIDENTES)",
  "(Other Services) Fomentar la seguridad,confraterni...",
  "(Other Services) Sociedad de Restauradores de Auto...",
  "(Other Services) Arbitrar juegos de baloncesto",
  "(Other Services) ASOCIACION DE PERIODISTAS",
  "(Other Services) ASOCIACION AGRICULTORES",
  "(Other Services) Servicios de iluminotecnico",
  "(Other Services) EDUCAR Y PROTEGER LA ABEJA Y LA A...",
  "(Other Services) Administrar cierre control de acc...",
  "(Other Services) Acceso Controlado",
  "(Other Services) Administrar sistema de control de...",
  "(Other Services) Adm Control de Acceso",
  "(Other Services) Administracion, adquisicion de lo...",
  "(Other Services) ASOCIACION DE RESIDENTEES",
  "(Other Services) Junta de comercio y publicidad",
  "(Other Services) Mantenimiento y Operacion de Acue...",
  "(Other Services) Organizar y adiestrar",
  "(Other Services) Club de Tiro y Caza",
  "(Other Services) ADMINISTRACION",
  "(Other Services) Rescate, cuido y albergue de perr...",
  "(Other Services) Educacion y Orientacion profesion...",
  "(Other Services) Compartir conocimientos y estrate...",
  "(Other Services) Organizacion profesional para ava...",
  "(Other Services) Servicios educativos y capacitaci...",
  "(Other Services) Administracion Facilidades Recrea...",
  "(Other Services) ASOC DE RESIDENTES",
  "(Other Services) Seguridad Comunitaria",
  "(Other Services) Desarrollo comunitario y recreati...",
  "(Other Services) Servicios a empleados AAA",
  "(Other Services) Servicios Religioso, Social, Comu...",
  "(Other Services) Adiestramientos y Capacitacion",
  "(Other Services) Operadores de Maquinas NO Adultos",
  "(Other Services) Educativo y Filantropico",
  "(Other Services) Asociacion de Periodistas y Medio...",
  "(Other Services) Asoc Urb residencial en Guaynabo ...",
  "(Other Services) Fomentar la educacion, la etica p...",
  "(Other Services) MATENIMIENTO DE AREAS COMUNES",
  "(Other Services) MEJORAMIENTO PROFESIONAL",
  "(Other Services) Seguridad local(fisica, mental y ...",
  "(Other Services) Criadores y Duenos de Caballos de...",
  "(Other Services) Hobby de peces",
  "(Other Services) Desarrollo economico, social, cul...",
  "(Other Services) Jardin de Descanso",
  "(Other Services) Servicios Educativos, desarrollo ...",
  "(Other Services) Servicio Social, Comunitario y Ba...",
  "(Other Services) SERVICIOS MULTIPLES",
  "(Other Services) Servicios de Consejeria y Teo-Ter...",
  "(Other Services) albergue y servicios basicos anim...",
  "(Other Services) Recoger y difundir informacion a ...",
  "(Other Services) EDUCACION PRIVADA",
  "(Other Services) Terapia y Desarrollo Educativo co...",
  "(Other Services) comite politico",
  "(Other Services) CENTRO MODELAJE Y REF.",
  "(Other Services) promover diseno",
  "(Other Services) Desarrollo del domino",
  "(Other Services) Adjuntas",
  "(Other Services) Agrupar, proteger y educar sobre ...",
  "(Other Services) Incorporar centro",
  "(Other Services) .",
  "(Other Services) Realizacion y organizacion de con...",
  "(Other Services) Promover Actividades de Campo de ...",
  "(Other Services) Educativo, Recreativos y Cultural...",
  "(Other Services) Asociacion de interes de politica...",
  "(Other Services) Educativa para crecimiento profes...",
  "(Other Services) Asociacion Profesional de Odontol...",
  "(Other Services) Asociacion Publicidad de Dealers",
  "(Other Services) IGLESIA",
  "(Other Services) Dar servicios a pequenos comercia...",
  "(Other Services) Unir Distribuidores de Gas Liquad...",
  "(Other Services) exihbicion de autos antiguos y cl...",
  "(Other Services) exhibicion de autos",
  "(Other Services) CREAR FIDEICOMISO DE ACCIONISTAS ...",
  "(Other Services) PROMOTORES DE ESPECTACULOS",
  "(Other Services) varios",
  "(Other Services) SERVICIOS DE ENSENAR TODO LO RELA...",
  "(Other Services) ASOCIACION DE PROFESIONALES",
  "(Other Services) PARA ABOGAR POR LEGISLACION FEDER...",
  "(Other Services) ACTIVIDADES CLUB AUTOS BRITANICOS",
  "(Other Services) Colegiacion Profesionales",
  "(Other Services) FORMACION,CAPACITACION Y REPRESEN...",
  "(Other Services) Educativas y Caritativas",
  "(Other Services) RECREACION , EDUCATIVA Y COMUNITA...",
  "(Other Services) Tropa de BSA",
  "(Other Services) Preservacion y cuidado del templo...",
  "(Other Services) Salud y medio ambiente (servicios...",
  "(Other Services) Servicios Recreacional, Social y ...",
  "(Other Services) LEGION AMERICANA",
  "(Other Services) Servicios a Agricultores/ Ganader...",
  "(Other Services) SERVICIOS EDUCACION MEDICA",
  "(Other Services) AYUDA SECTORES DESAVENTAJADOS COL...",
  "(Other Services) Animal Rescue",
  "(Other Services) ACTIVIDADES ACADEMICAS, DEPORTIVA...",
  "(Other Services) Control de Acceso Residentes",
  "(Other Services) SERVICIOS A AGENCIAS DE VIAJEA",
  "(Other Services) coro",
  "(Other Services) Asoc. Profesional de Adm de Proye...",
  "(Other Services) Cooperativa de Ahorrro y Credito",
  "(Other Services) Servicios a Veteranos y Familiare...",
  "(Other Services) Teatro",
  "(Other Services) SERVICE TO DISABLED VETERANS",
  "(Other Services) ASOCIACION DE COMERCIANTES",
  "(Other Services) Conservar y reproducir especies",
  "(Other Services) UNION DE TAXISTA",
  "(Other Services) CARITATIVO",
  "(Other Services) Preparacion Iniciatica Mayor",
  "(Other Services) Intermediacion Financiera",
  "(Other Services) Talleres de cultivo de orquideas.",
  "(Other Services) Cooperativa de Ahorro y Credito",
  "(Other Services) servicios a la comunidad y los ve...",
  "(Other Services) Civic",
  "(Other Services) Servicios de Capacitacion",
  "(Other Services) Salud, educativo, culturales, eco...",
  "(Other Services) Servicios profesionales y educati...",
  "(Other Services) Organizacion profesional",
  "(Other Services) Promover La Radio Amateur",
  "(Other Services) Asociacion Medica",
  "(Other Services) Servicios y Asesoria a Fotoperiod...",
  "(Other Services) SERVICIOS FINANCIEROS",
  "(Other Services) COOP A/C",
  "(Other Services) Desarrollo economico",
  "(Other Services) REUNION DE VETERANOS",
  "(Other Services) Ensenanza, practica y competencia...",
  "(Other Services) Rehabilitacion de Adictos",
  "(Other Services) Practicar arte del bonsai",
  "(Other Services) Asociacion de Realtors",
  "(Other Services) Asociacion de Graduados",
  "(Other Services) PROMOVER Y PROTEGER EL BIENESTAR ...",
  "(Other Services) Organizacion Estudiantil",
  "(Other Services) Asociacion periodistica",
  "(Other Services) Ver Nota No. 1 de Estados Financi...",
  "(Other Services) Servicios a los veteranos de guer...",
  "(Other Services) ESCUELA PARA NINOS SORDOS",
  "(Other Services) AUTOS ANTIGUOS",
  "(Other Services) REHABILITATION TO DRUG ADDICTS & ...",
  "(Other Services) Asociacion Profesional Microbiolo...",
  "(Other Services) SERVICIOS A BANCOS MIEMBROS",
  "(Other Services) Asociacion de Constructores",
  "(Other Services) Servicios de ensenanza (colegio)",
  "(Other Services) Asociacion de Exterminadores",
  "(Other Services) Ayuda a la profesion",
  "(Other Services) Sales & marketing of PR for conve...",
  "(Other Services) Jardineria Educativa",
  "(Other Services) INDUSTRIA HOTELERA",
  "(Other Services) Cuido de adultos Retardacion Ment...",
  "(Other Services) Scouting Program for Boys",
  "(Other Services) Investigacion y Educacion en cien...",
  "(Other Services) COOPERATIVA ES DE SERVICIOS",
  "(Other Services) Manufacturers Alliance",
  "(Other Services) defensa del ambiente y caceria en...",
  "(Other Services) Educativa",
  "(Other Services) CUIDO DE NINO",
  "(Other Services) Casa de Retiro",
  "(Other Services) Facilitate important and complex ...",
  "(Other Services) Gremio de Profesores Universitari...",
  "(Other Services) ASOCIACION DE PERSONAL DOCENTE",
  "(Other Services) Servicios Profesionales a maestro...",
  "(Other Services) Arbitraje",
  "(Other Services) Fundraising for 2022 Senior Class",
  "(Other Services) ASOCIACION CON BASE COMUNITARIA",
  "(Other Services) Ensenar y Capacitar lideres de Ig...",
  "(Other Services) SERVICIOS DE ARBITRAJE DE FUTBOL ...",
  "(Other Services) Espectaculos",
  "(Other Services) Colegio Educativo",
  "(Other Services) Colegio Nivel Elemental - Secunda...",
  "(Other Services) Ensenanza Escolar",
  "(Other Services) COLEGIO - NIVEL ELEMENTAL",
  "(Other Services) Colegio y hogar de ninas",
  "(Other Services) Servicios educativos y religiosos",
  "(Other Services) Herreria",
  "(Other Services) Charitable and Scientific",
  "(Other Services) Servicios Sociales y de Salud",
  "(Other Services) FUNDACION",
  "(Other Services) Patriotic, non partison Veterans ...",
  "(Other Services) Sancionar Peleas de Boxeo,Donacio...",
  "(Other Services) Spiritual Services",
  "(Other Services) union de transportistas de cadave...",
  "(Other Services) AFILIAR JUBILADOS Y PENSIONADOS",
  "(Other Services) SERVICIOS AMBIENTALES, PROTECCION...",
  "(Other Services) Reconocimientos",
  "(Other Services) Servicios Integrales de Apoyo a l...",
  "(Other Services) Centro comunitario para ninos y j...",
  "(Other Services) POLITICA",
  "(Other Services) EDUCATIONAL ASSISTANCE, HOMELESS ...",
  "(Other Services) Promover bienestar social",
  "(Other Services) Animal protection",
  "(Other Services) Recuperacion y Desarrollo Socioec...",
  "(Other Services) liga de negocios",
  "(Other Services) Ayudar a Familiares de Confinados",
  "(Other Services) Art, Education and Social Enterta...",
  "(Other Services) Ayuda a los ninos",
  "(Other Services) RECAUDACION DE FONDOS",
  "(Other Services) Educacion Psicosocial Preventiva ...",
  "(Other Services) Charitable, Sports Promotion and ...",
  "(Other Services) org",
  "(Other Services) diversidad de servicios de apoyo",
  "(Other Services) Social con base de Fe",
  "(Other Services) Operacion Comunitaria",
  "(Other Services) deportivos, educativos, civicos y...",
  "(Other Services) Ayuda social,educativa y de salud",
  "(Other Services) Servicios comunitarios y educativ...",
  "(Other Services) Cancer Patient Assistance",
  "(Other Services) bienestar maltrato animal",
];

export const optionsCustomRange = [
  { key: "none", label: "None" },
  { key: "less_500k", label: "Less Than 500K $" },
  { key: "less_1M", label: "Less Than 1M $" },
  { key: "less_2M", label: "Less Than 2M $" },
  { key: "greater_3M", label: "Greater Than 3M $" },
];
