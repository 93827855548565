import React, { createContext, useState } from "react";

import PropTypes from "prop-types";

export const LoginContext = createContext();

/**
 * It's a React Context Provider that provides the state of the login form to the rest of the app.
 * @returns The LoginProvider is returning the LoginContext.Provider.
 */
export const LoginProvider = ({ children }) => {
  const [isLoginLoading, setLoginLoading] = useState(false);
  const [loginStatusError, setLoginStatusError] = useState(false);

  return (
    <LoginContext.Provider
      value={{
        isLoginLoading,
        setLoginLoading,
        loginStatusError,
        setLoginStatusError,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

// Defaults props
LoginProvider.defaultProps = {
  children: "",
};

// Typechecking props
LoginProvider.propTypes = {
  children: PropTypes.node,
};
