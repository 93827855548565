/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/*
=========================================================
* Ceyba
=========================================================

Coded by Andres Lista

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the
Software.
*/
import { useContext, useState, forwardRef, useEffect } from "react";
// import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// context
import { AdvancedSearchContext } from "context/AdvancedSearchContext";

// Material Dashboard
import MDBox from "components/MDBox";

// Material Dashboard
import DashboardLayout from "components_base/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components_base/Navbars/DashboardNavbar";
import Footer from "components_base/Footer";
import Checkbox from "@mui/material/Checkbox";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Autocomplete,
} from "@mui/material";

// Dashboard components
import AdvanceSearchResults from "layouts/advance_search/components/AdvancedSearchResults";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDModal from "components/MDModal/MDModal";
import DatePicker from "react-datepicker";
// import MDTextFieldMultiline from "components/MDTextFieldMultiline/MDTextFieldMultiline";

// options select
import {
  optionSelect,
  optionSelectClass,
  optionSelectType,
  optionSelectRevenues,
  optionSelectJurisdiction,
} from "layouts/advance_search/data/selectSearchData";

// icons
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchIcon from "@mui/icons-material/Search";
import ClearAllIcon from "@mui/icons-material/ClearAll";

// api service
import { advanceSearch, getAllZipCodes } from "api/apiService";

// utils
import { normalizeDate } from "utils/util";

// Modal Field Selection Content
import { Box } from "@mui/system";
import MDInput from "components/MDInput";
// import LoadingButton from "@mui/lab/LoadingButton";
import AdvancedFieldCSVExport from "./components/AdvancedFieldCSVExport";

// react -datepicker
import "react-datepicker/dist/react-datepicker.css";
import MDSelect from "components/MDSelect";

/**
 * It renders a modal that allows the user to select which fields to export to a CSV file
 * @returns The return statement is used to return a value from a function.
 */
function AdvanceSearch() {
  const {
    setAdvanceCorpList,
    corpAdvanceList,
    isAdvanceSearchLoading,
    setAdvanceSearchLoading,
  } = useContext(AdvancedSearchContext);
  const [isOpen, setIsOpen] = useState(false);
  const [byTA, setTA] = useState("");
  const [byMinTA, setMinTA] = useState("");
  const [byMaxTA, setMaxTA] = useState("");
  const [byMinEQ, setMinEQ] = useState("");
  const [byMaxEQ, setMaxEQ] = useState("");
  const [byMinPE, setMinPE] = useState("");
  const [byMaxPE, setMaxPE] = useState("");
  const [byMinCL, setMinCL] = useState("");
  const [byMaxCL, setMaxCL] = useState("");
  const [byEQ, setEQ] = useState("");
  const [byPE, setPE] = useState("");
  const [byCL, setCL] = useState("");
  const [byTYPE, setTYPE] = useState("");
  const [byJURISDICTION, setJURISDICTION] = useState("");
  const [byREVENUES, setREVENUES] = useState("");
  const [byKEYWORDS, setKEYWORDS] = useState("");
  const [byEXCLUDEWORDS, setEXCLUDEWORDS] = useState("");
  const [byDATE, setDATE] = useState("");
  const [byEMAIL, setEMAIL] = useState(false);
  const [byYEAR, setYEAR] = useState("2020");
  const [byPHONE, setPHONE] = useState(false);
  const [byCLASS, setCLASS] = useState("");
  const [byZIPCODE, setZIPCODE] = useState("");
  const [optionsZipCodes, setOptionsZipCodes] = useState([]);
  const [byOFFICERNAME, setOFFICERNAME] = useState("");
  const [showCustomRangeTA, setShowCustomRangeTA] = useState(false);
  const [showCustomRangeEQ, setShowCustomRangeEQ] = useState(false);
  const [showCustomRangePE, setShowCustomRangePE] = useState(false);
  const [showCustomRangeCL, setShowCustomRangeCL] = useState(false);
  const [errorCustomRangeTA, setErrorCustomRangeTA] = useState(false);
  const [errorCustomRangeEQ, setErrorCustomRangeEQ] = useState(false);
  const [errorCustomRangePE, setErrorCustomRangePE] = useState(false);
  const [errorCustomRangeCL, setErrorCustomRangeCL] = useState(false);

  useEffect(() => {
    try {
      const zipCodes = getAllZipCodes();
      zipCodes
        .then((res) => setOptionsZipCodes(res.zip_codes))
        .catch((error) => setOptionsZipCodes([]));
    } catch (error) {
      setOptionsZipCodes([]);
    }
  }, []);

  const handleOpenModal = () => {
    setIsOpen(true);
    return false;
  };

  const handleShowCustomRangeTA = () => {
    setShowCustomRangeTA(true);
  };

  const handleShowCustomRangeEQ = () => {
    setShowCustomRangeEQ(true);
  };

  const handleShowCustomRangePE = () => {
    setShowCustomRangePE(true);
  };

  const handleShowCustomRangeCL = () => {
    setShowCustomRangeCL(true);
  };

  const handleCloseModal = () => setIsOpen(false);

  const handleSearchButton = async (e) => {
    e.preventDefault();
    setAdvanceSearchLoading(true);
    try {
      setAdvanceCorpList([]);
      const optSelected = {
        by_ta: byTA,
        by_eq: byEQ,
        by_pe: byPE,
        by_cl: byCL,
        by_type: byTYPE,
        by_jur: byJURISDICTION,
        by_rev: byREVENUES,
        by_keyword: byKEYWORDS,
        by_officer_name: byOFFICERNAME,
        by_exclude_words: byEXCLUDEWORDS,
        by_zip_code: byZIPCODE,
        by_date: byDATE,
        by_email: byEMAIL,
        by_phone: byPHONE,
        by_class: byCLASS,
        by_year: byYEAR,
        by_min_ta: byMinTA,
        by_max_ta: byMaxTA,
        by_min_eq: byMinEQ,
        by_max_eq: byMaxEQ,
        by_min_pe: byMinPE,
        by_max_pe: byMaxPE,
        by_min_cl: byMinCL,
        by_max_cl: byMaxCL,
      };

      if (parseInt(byMinTA, 10) >= parseInt(byMaxTA, 10)) {
        setErrorCustomRangeTA(true);
        setAdvanceSearchLoading(false);
        return;
      }
      if (parseInt(byMinEQ, 10) >= parseInt(byMaxEQ, 10)) {
        setErrorCustomRangeEQ(true);
        setAdvanceSearchLoading(false);
        return;
      }

      if (parseInt(byMinPE, 10) >= parseInt(byMaxPE, 10)) {
        setErrorCustomRangePE(true);
        setAdvanceSearchLoading(false);
        return;
      }

      if (parseInt(byMinCL, 10) >= parseInt(byMaxCL, 10)) {
        setErrorCustomRangeCL(true);
        setAdvanceSearchLoading(false);
        return;
      }

      setErrorCustomRangeTA(false);
      setErrorCustomRangeEQ(false);
      setErrorCustomRangePE(false);
      setErrorCustomRangeCL(false);
      const resDataSearch = await advanceSearch(optSelected);
      setAdvanceCorpList(resDataSearch);
      setAdvanceSearchLoading(false);
    } catch (error) {
      setAdvanceCorpList([]);
      setAdvanceSearchLoading(false);
    }
  };

  const handleClearFilters = () => {
    setTA("");
    setEQ("");
    setPE("");
    setCL("");
    setJURISDICTION("");
    setREVENUES("");
    setTYPE("");
    setKEYWORDS("");
    setDATE("");
    setEMAIL(false);
    setPHONE(false);
    setZIPCODE("");
    setEXCLUDEWORDS("");
    setCLASS("");
    setMinTA("");
    setMaxTA("");
    setMinEQ("");
    setMaxEQ("");
    setMinPE("");
    setMaxPE("");
    setMinCL("");
    setMaxCL("");
    setYEAR("2020");
    setShowCustomRangeTA(false);
    setShowCustomRangeEQ(false);
    setShowCustomRangePE(false);
    setShowCustomRangeCL(false);
    setErrorCustomRangeTA(false);
    setErrorCustomRangeEQ(false);
    setErrorCustomRangePE(false);
    setErrorCustomRangeCL(false);
  };

  const handleChangeTA = (e) => {
    setTA(e.target.value);
    if (e.target.value !== "custom_range") {
      setShowCustomRangeTA(false);
      setMinTA("");
      setMaxTA("");
    }
  };

  const handleChangeEQ = (e) => {
    setEQ(e.target.value);
    if (e.target.value !== "custom_range") {
      setShowCustomRangeEQ(false);
      setMinEQ("");
      setMaxEQ("");
    }
  };

  const handleChangePE = (e) => {
    setPE(e.target.value);
    if (e.target.value !== "custom_range") {
      setShowCustomRangePE(false);
      setMinPE("");
      setMaxPE("");
    }
  };

  const handleChangeCL = (e) => {
    setCL(e.target.value);
    if (e.target.value !== "custom_range") {
      setShowCustomRangeCL(false);
      setMinCL("");
      setMaxCL("");
    }
  };

  const handleChangeTYPE = (e) => {
    setTYPE(e.target.value);
  };

  const handleChangeYEAR = (e) => {
    setYEAR(e.target.value);
  };

  const handleChangeJURISDICTION = (e) => {
    setJURISDICTION(e.target.value);
  };

  const handleChangeREVENUES = (e) => {
    setREVENUES(e.target.value);
  };

  const handleChangeKEYWORDS = (e) => {
    setKEYWORDS(e.target.value);
  };

  const handleChangeDATE = (date, event) => {
    setDATE(date);
  };

  const handleCheckedEMAIL = (event) => {
    setEMAIL(event.target.checked);
  };

  const handleCheckedPHONE = (event) => {
    setPHONE(event.target.checked);
  };

  const handleChangeCLASS = (event) => {
    setCLASS(event.target.value);
  };

  const handleChangeZIPCODE = (event) => {
    setZIPCODE(event.target.value);
  };

  const handleChangeOFFICERNAME = (event) => {
    setOFFICERNAME(event.target.value);
  };

  const handleChangeEXCLUDEWORDS = (event) => {
    setEXCLUDEWORDS(event.target.value);
  };

  const handleMinTA = (e) => {
    const { value } = e.target;
    setMinTA(value);
  };

  const handleMaxTA = (e) => {
    const { value } = e.target;
    setMaxTA(value);
  };

  const handleMinEQ = (e) => {
    const { value } = e.target;
    setMinEQ(value);
  };

  const handleMaxEQ = (e) => {
    const { value } = e.target;
    setMaxEQ(value);
  };

  const handleMinPE = (e) => {
    const { value } = e.target;
    setMinPE(value);
  };

  const handleMaxPE = (e) => {
    const { value } = e.target;
    setMaxPE(value);
  };

  const handleMinCL = (e) => {
    const { value } = e.target;
    setMinCL(value);
  };

  const handleMaxCL = (e) => {
    const { value } = e.target;
    setMaxCL(value);
  };

  const SearchDatePicker = () => {
    const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
      <TextField
        id="outlined-helperText"
        label="Formation Date"
        onClick={onClick}
        autoComplete="off"
        value={value}
        fullWidth
      />
    ));

    return (
      <FormControl fullWidth>
        <DatePicker
          selected={byDATE}
          onChange={(date, event) => handleChangeDATE(date, event)}
          disabledKeyboardNavigation
          showMonthYearPicker
          placeholderText="Formation Date"
          dateFormat="MM/yyyy"
          showFourColumnMonthYearPicker
          customInput={<DateCustomInput />}
        />
      </FormControl>
    );
  };

  return (
    <MDBox component="div" data-aos="fade-in">
      <MDModal
        title="Select fields to export"
        content={<AdvancedFieldCSVExport onCloseModal={handleCloseModal} />}
        isOpen={isOpen}
        onOpen={handleOpenModal}
        onClose={handleCloseModal}
      />
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox>
          <MDBox
            style={{
              border: "0.5px solid #eceff1",
              borderRadius: 8,
              padding: 8,
            }}
          >
            <Grid
              container
              spacing={3}
              justifyContent="flex-start"
              alignItems="center"
              direction="row"
            >
              <Grid item xs={12} md={12} lg={12}>
                <MDBox
                  id="search-form-filters"
                  display="flex"
                  width="100%"
                  style={{ padding: "4px 16px" }}
                >
                  <Grid
                    container
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={12} lg={12}>
                      <MDTypography>Fields to search</MDTypography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox
                        style={{
                          border: "0.5px solid #eceff1",
                          borderRadius: 8,
                          padding: 8,
                          width: "fit-content",
                        }}
                      >
                        <FormGroup style={{ marginLeft: "16px" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="by_check"
                                checked={byEMAIL}
                                onChange={handleCheckedEMAIL}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Show only results with e-mail(s)"
                          />
                        </FormGroup>
                        <FormGroup style={{ marginLeft: "16px" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="by_check"
                                checked={byPHONE}
                                onChange={handleCheckedPHONE}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Show only results with phone numbers"
                          />
                        </FormGroup>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <form onSubmit={(e) => handleSearchButton(e)}>
                        <MDInput
                          id="outlined-helperText"
                          label="Search by corporation name, purpose"
                          value={byKEYWORDS}
                          onChange={handleChangeKEYWORDS}
                          fullWidth
                          autoComplete="off"
                        />
                      </form>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <form onSubmit={(e) => handleSearchButton(e)}>
                        <MDInput
                          id="outlined-helperText"
                          label="Officer name"
                          value={byOFFICERNAME}
                          onChange={handleChangeOFFICERNAME}
                          fullWidth
                          autoComplete="off"
                        />
                      </form>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <form onSubmit={(e) => handleSearchButton(e)}>
                        <MDInput
                          id="outlined-helperText"
                          label="Exclude words from search result"
                          value={byEXCLUDEWORDS}
                          onChange={handleChangeEXCLUDEWORDS}
                          fullWidth
                          autoComplete="off"
                        />
                      </form>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormControl sx={{ m: 1, minWidth: "11ch"}} size="medium">
                        <InputLabel id="select-label-year">Annual Filing Year</InputLabel>
                        <Select
                          labelId="select-label-year"
                          displayEmpty
                          name="by_year"
                          label="Annual Filing Year"
                          value={byYEAR}
                          autoWidth
                          defaultValue="2020"
                          onChange={handleChangeYEAR}
                          sx={{ height: 43 }}
                        >
                          {["2019", "2020", "2021"].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      md={12}
                      lg={12}
                      padding={2}
                      spacing={1}
                    >
                      <Grid item xs={12} md={2} lg={2}>
                        <SearchDatePicker />
                      </Grid>
                      <Grid item xs={12} md={2} lg={2}>
                        <FormControl fullWidth>
                          <InputLabel
                            id="select-type"
                            name="by_type"
                            style={{ zIndex: 0 }}
                          >
                            Type
                          </InputLabel>
                          <Select
                            id="select-type"
                            displayEmpty
                            name="by_type"
                            label="Type"
                            variant="outlined"
                            fullWidth
                            value={byTYPE}
                            onChange={handleChangeTYPE}
                            sx={{ height: 42 }}
                          >
                            {optionSelectType.map((option) => (
                              <MenuItem key={option.key} value={option.key}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} lg={2}>
                        <FormControl fullWidth>
                          <InputLabel id="select-label-1" style={{ zIndex: 0 }}>
                            Jurisdiction
                          </InputLabel>
                          <Select
                            labelId="select-label-1"
                            name="by_jur"
                            label="Jurisdiction"
                            variant="outlined"
                            sx={{ height: 42 }}
                            value={byJURISDICTION}
                            onChange={handleChangeJURISDICTION}
                          >
                            {optionSelectJurisdiction.map((option) => (
                              <MenuItem key={option.key} value={option.key}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} lg={2}>
                        <FormControl fullWidth>
                          <InputLabel id="select-label-1" style={{ zIndex: 0 }}>
                            Revenues
                          </InputLabel>
                          <Select
                            labelId="select-label-1"
                            name="by_rev"
                            label="Revenues"
                            variant="outlined"
                            sx={{ height: 42 }}
                            value={byREVENUES}
                            onChange={handleChangeREVENUES}
                          >
                            {optionSelectRevenues.map((option) => (
                              <MenuItem key={option.key} value={option.key}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} lg={2}>
                        <FormControl fullWidth>
                          <InputLabel id="select-label-1" style={{ zIndex: 0 }}>
                            Class
                          </InputLabel>
                          <Select
                            labelId="select-label-1"
                            displayEmpty
                            name="by_class"
                            label="Class"
                            variant="outlined"
                            sx={{ height: 42 }}
                            value={byCLASS}
                            onChange={handleChangeCLASS}
                          >
                            <MenuItem key="none" value="None">
                              None
                            </MenuItem>
                            {optionSelectClass.sort().map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} lg={2}>
                        <FormControl fullWidth>
                          <InputLabel id="select-label-1" style={{ zIndex: 0 }}>
                            Zip Code
                          </InputLabel>
                          <Select
                            labelId="select-label-1"
                            displayEmpty
                            name="by_zip_code"
                            label="Zip Code"
                            variant="outlined"
                            sx={{ height: 42 }}
                            value={byZIPCODE}
                            onChange={handleChangeZIPCODE}
                          >
                            {optionsZipCodes.sort().map((option) => (
                              <MenuItem
                                key={option.zip_code}
                                value={option.zip_code}
                              >
                                {option.zip_code} - {option.primary_city}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/******************************* */}

                    <Grid item xs={12} md={12} lg={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={2} lg={3}>
                          <FormControl fullWidth>
                            <InputLabel id="select-label" style={{ zIndex: 0 }}>
                              Total Assets
                            </InputLabel>
                            <Select
                              name="by_ta"
                              label="Total Assets"
                              variant="outlined"
                              sx={{ height: 42 }}
                              value={byTA}
                              onChange={handleChangeTA}
                            >
                              {optionSelect.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                  {option.label}
                                </MenuItem>
                              ))}
                              <MenuItem
                                key="custom_range"
                                value="custom_range"
                                onClick={handleShowCustomRangeTA}
                              >
                                Custom Range
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <Box
                            display={showCustomRangeTA ? "flex" : "none"}
                            mt={2}
                          >
                            <MDInput
                              name="min_ta"
                              // error={errorFieldCSV}
                              label="Min. Value"
                              value={byMinTA}
                              isNumeric
                              error={errorCustomRangeTA}
                              onChange={handleMinTA}
                              sx={{ minHeight: 48, marginRight: 2 }}
                            />
                            <MDInput
                              name="max_ta"
                              // error={errorFieldCSV}
                              label="Max. Value"
                              value={byMaxTA}
                              isNumeric
                              error={errorCustomRangeTA}
                              onChange={handleMaxTA}
                              sx={{ minHeight: 48 }}
                            />
                          </Box>
                          <MDTypography
                            style={{
                              display: errorCustomRangeTA ? "flex" : "none",
                            }}
                            color="error"
                            fontSize={12}
                          >
                            Check min/max values
                          </MDTypography>
                        </Grid>
                        <Grid item xs={12} md={2} lg={3}>
                          <FormControl fullWidth>
                            <InputLabel id="select-label" style={{ zIndex: 0 }}>
                              Equity
                            </InputLabel>
                            <Select
                              name="by_eq"
                              label="Equity"
                              variant="outlined"
                              sx={{ height: 42 }}
                              value={byEQ}
                              onChange={handleChangeEQ}
                            >
                              {optionSelect.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                  {option.label}
                                </MenuItem>
                              ))}
                              <MenuItem
                                key="custom_range"
                                value="custom_range"
                                onClick={handleShowCustomRangeEQ}
                              >
                                Custom Range
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <Box
                            display={showCustomRangeEQ ? "flex" : "none"}
                            mt={2}
                          >
                            <MDInput
                              name="min_eq"
                              // error={errorFieldCSV}
                              label="Min. Value"
                              value={byMinEQ}
                              isNumeric
                              error={errorCustomRangeEQ}
                              onChange={handleMinEQ}
                              sx={{ minHeight: 48, marginRight: 2 }}
                            />
                            <MDInput
                              name="max_eq"
                              // error={errorFieldCSV}
                              label="Max. Value"
                              value={byMaxEQ}
                              isNumeric
                              error={errorCustomRangeEQ}
                              onChange={handleMaxEQ}
                              sx={{ minHeight: 48 }}
                            />
                          </Box>
                          <MDTypography
                            style={{
                              display: errorCustomRangeEQ ? "flex" : "none",
                            }}
                            color="error"
                            fontSize={12}
                          >
                            Check min/max values
                          </MDTypography>
                        </Grid>
                        <Grid item xs={12} md={2} lg={3}>
                          <FormControl fullWidth>
                            <InputLabel id="select-label" style={{ zIndex: 0 }}>
                              Property & Equipment
                            </InputLabel>
                            <Select
                              name="by_pe"
                              label="Property & Equipment"
                              variant="outlined"
                              sx={{ height: 42 }}
                              value={byPE}
                              onChange={handleChangePE}
                            >
                              {optionSelect.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                  {option.label}
                                </MenuItem>
                              ))}
                              <MenuItem
                                key="custom_range"
                                value="custom_range"
                                onClick={handleShowCustomRangePE}
                              >
                                Custom Range
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <Box
                            display={showCustomRangePE ? "flex" : "none"}
                            mt={2}
                          >
                            <MDInput
                              name="min_pe"
                              // error={errorFieldCSV}
                              label="Min. Value"
                              value={byMinPE}
                              isNumeric
                              error={errorCustomRangePE}
                              onChange={handleMinPE}
                              sx={{ minHeight: 48, marginRight: 2 }}
                            />
                            <MDInput
                              name="max_pe"
                              // error={errorFieldCSV}
                              label="Max. Value"
                              value={byMaxPE}
                              isNumeric
                              error={errorCustomRangePE}
                              onChange={handleMaxPE}
                              sx={{ minHeight: 48 }}
                            />
                          </Box>
                          <MDTypography
                            style={{
                              display: errorCustomRangePE ? "flex" : "none",
                            }}
                            color="error"
                            fontSize={12}
                          >
                            Check min/max values
                          </MDTypography>
                        </Grid>
                        <Grid item xs={12} md={2} lg={3}>
                          <FormControl fullWidth>
                            <InputLabel id="select-label" style={{ zIndex: 0 }}>
                              Current Liabilities
                            </InputLabel>
                            <Select
                              name="by_cl"
                              label="Current Liabilities"
                              variant="outlined"
                              sx={{ height: 42 }}
                              value={byCL}
                              onChange={handleChangeCL}
                            >
                              {optionSelect.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                  {option.label}
                                </MenuItem>
                              ))}
                              <MenuItem
                                key="custom_range"
                                value="custom_range"
                                onClick={handleShowCustomRangeCL}
                              >
                                Custom Range
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <Box
                            display={showCustomRangeCL ? "flex" : "none"}
                            mt={2}
                          >
                            <MDInput
                              name="min_cl"
                              // error={errorFieldCSV}
                              label="Min. Value"
                              value={byMinCL}
                              isNumeric
                              error={errorCustomRangeCL}
                              onChange={handleMinCL}
                              sx={{ minHeight: 48, marginRight: 2 }}
                            />
                            <MDInput
                              name="max_cl"
                              // error={errorFieldCSV}
                              label="Max. Value"
                              value={byMaxCL}
                              error={errorCustomRangeCL}
                              isNumeric
                              onChange={handleMaxCL}
                              sx={{ minHeight: 48 }}
                            />
                          </Box>
                          <MDTypography
                            style={{
                              display: errorCustomRangeCL ? "flex" : "none",
                            }}
                            color="error"
                            fontSize={12}
                          >
                            Check min/max values
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox
                  width="100%"
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <MDButton
                    color="info"
                    variant="gradient"
                    endIcon={<SearchIcon fontSize="large" />}
                    style={{
                      marginRight: 32,
                    }}
                    onClick={handleSearchButton}
                    disabled={isAdvanceSearchLoading}
                    loading={isAdvanceSearchLoading}
                    loadingIndicator="Loading..."
                    type="submit"
                  >
                    Search
                  </MDButton>
                  <MDButton
                    color="info"
                    variant="gradient"
                    endIcon={<ClearAllIcon fontSize="large" />}
                    style={{ marginleft: 32, marginRight: 32 }}
                    onClick={handleClearFilters}
                    disabled={isAdvanceSearchLoading}
                    loading={isAdvanceSearchLoading}
                  >
                    clear all fields
                  </MDButton>
                  <div
                    style={{
                      display: corpAdvanceList.length <= 0 ? "none" : "flex",
                    }}
                    data-aos="fade-up"
                  >
                    <MDButton
                      color="success"
                      variant="gradient"
                      endIcon={<FileDownloadIcon />}
                      onClick={handleOpenModal}
                      disabled={isAdvanceSearchLoading}
                      loading={isAdvanceSearchLoading}
                    >
                      export table results (CSV)
                    </MDButton>
                  </div>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox>
                  <AdvanceSearchResults />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </MDBox>
  );
}

export default AdvanceSearch;
