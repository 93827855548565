/*
=========================================================
* Ceyba
=========================================================

Coded by Andres Lista

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the
Software.
*/

import { useContext, useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
/* import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link"; */

// @mui icons
/* import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
*/

// Material Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  CircularProgress,
  FormControl,
  Hidden,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

// Context login
import { LoginContext } from "context/LoginContext";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Authentication
import auth from "authentication/fbAuthentication";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import logoImage from "assets/images/logo-ceyba-light.svg";

// Utils
// import { setCookie } from "../../../utils/util";

/**
 * It renders a login form with a background image
 */
function Basic() {
  const [showPassword, setShowPassword] = useState(false);
  // const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  const {
    isLoginLoading,
    setLoginLoading,
    loginStatusError,
    setLoginStatusError,
  } = useContext(LoginContext);

  const handleLogin = async () => {
    setLoginLoading(true);
    setLoginStatusError(false);

    try {
      const resp = await auth.login(email, password, () =>
        navigate("/dashboard")
      );
      if (!resp.auth) setLoginStatusError(true);
      setLoginLoading(false);
    } catch (error) {
      setLoginLoading(false);
      setLoginStatusError(true);
    }
  };

  const handleChangeInputEmail = (e) => {
    setEmail(e.target.value);
    setLoginStatusError(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeInputPassword = (e) => {
    setPassword(e.target.value);
    setLoginStatusError(false);
  };

  return (
    <BasicLayout image={bgImage}>
      <Card data-aos="fade-up" data-aos-duration="1000">
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={0}
          mb={1}
          textAlign="center"
        >
          <Hidden smUp>
            <img src={logoImage} alt="logo ceyba" width="auto" height="256px" />
          </Hidden>
          <Hidden smDown>
            <img src={logoImage} alt="logo ceyba" width="auto" height="128px" />
          </Hidden>
        </MDBox>
        <MDBox>
          <MDBox
            component="form"
            pt={4}
            pl={3}
            pr={3}
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MDBox mb={2} sx={{ width: "100%" }}>
              <MDInput
                disabled={isLoginLoading}
                placeholder="Email"
                error={loginStatusError}
                fullWidth
                onChange={handleChangeInputEmail}
                value={email || ""}
              />
            </MDBox>
            <MDBox mb={2} sx={{ width: "100%" }}>
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-password"
                  fullWidth
                  error={loginStatusError}
                  disabled={isLoginLoading}
                  value={password || ""}
                  onChange={handleChangeInputPassword}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        color="info"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="Password"
                />
              </FormControl>
            </MDBox>
            <MDBox
              display={isLoginLoading ? "flex" : "none"}
              justifyContent="center"
              alignItems="center"
              m={2}
            >
              <CircularProgress color="info" />
            </MDBox>
            <MDBox
              display={loginStatusError ? "flex" : "none"}
              justifyContent="center"
              alignItems="center"
            >
              <MDTypography variant="button" color="warning">
                Wrong username/password
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                type="submit"
                variant="gradient"
                disabled={isLoginLoading}
                color="info"
                sx={{ width: "25ch" }}
                onClick={(e) => handleLogin(e)}
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <a href="https://ceyba.io" target="_blank" rel="noreferrer">
                  <MDTypography variant="button" color="info">
                    Contact us
                  </MDTypography>
                </a>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
