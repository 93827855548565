import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

// Custom styles for MDInput
import MDInputRoot from "components/MDInput/MDInputRoot";

const NumberFormatCustom = forwardRef((props, ref) => {
  const { onChange, isNumeric, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
});

NumberFormatCustom.defaultProps = {
  isNumeric: false,
};

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isNumeric: PropTypes.bool,
};

const MDInput = forwardRef(({ error, success, disabled, isNumeric, ...rest }, ref) => (
  <MDInputRoot
    {...rest}
    ref={ref}
    ownerState={{ error, success, disabled }}
    InputProps={{ inputComponent: isNumeric && NumberFormatCustom }}
  />
));

// Setting default values for the props of MDInput
MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
  isNumeric: false,
};

// Typechecking props for the MDInput
MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  isNumeric: PropTypes.bool,
};

export default MDInput;
