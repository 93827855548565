import { Icon } from "@mui/material";
import MDTypography from "components/MDTypography";

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export const parseJsonData = (value) => {
  const jsonData = JSON.parse(value);
  const arrData = [];
  if (Array.isArray(jsonData) && jsonData !== null && jsonData !== "[]") {
    // return jsonData;
    const arrResult = jsonData.map((obj) => obj.name);
    const filtered = jsonData.filter(
      ({ name }, index) => !arrResult.includes(name, index + 1)
    );

    return filtered;
  }
  arrData.push(JSON.parse(value));
  const arrResult = arrData.map((obj) => obj.name);
  const filtered = arrData.filter(
    ({ name }, index) => !arrResult.includes(name, index + 1)
  );

  return filtered;
};

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function deleteCookie(cname) {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function convertToCSV(arr) {
  const array = [Object.keys(arr[0])].concat(arr);
  return array.map((it) => Object.values(it).toString()).join("\n");
}

export function capitalizeFirstLetter(string) {
  return string.replace("_", " ").charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeStr(str = "") {
  const arr = str.toLowerCase().replace("_", " ").replace(",", "").split(" ");
  for (let i = 0; i < arr.length; i += 1) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
}

export function normalizeDate(date) {
  let finalDateStr = "Unknown";
  try {
    const dateUTC = date.split("T");
    const [d] = dateUTC;
    const dateStr = d.split("-");
    const [year, month, day] = dateStr;
    finalDateStr = month.concat("-").concat(day).concat("-").concat(year);
  } catch (error) {
    finalDateStr = "Unknown";
  }
  return finalDateStr;
}

/**
 * It takes a string value and returns a string value.
 * @param value - the value of the field in the database
 * @returns the value of the variable revenues.
 */
export const getRevenuesValue = (value) => {
  let revenues = "No records on file";
  switch (value) {
    case "True":
      revenues = (
        <MDTypography variant="text" fontWeight="regular" color="success">
          Exceeds 3 MM dollars
        </MDTypography>
      );
      break;
    case "False":
      revenues = (
        <MDTypography variant="text" fontWeight="regular" color="warning">
          Does not exceed 3 MM dollars
        </MDTypography>
      );
      break;
    default:
      revenues = (
        <MDTypography variant="error" fontWeight="regular" color="error">
          No records on file
        </MDTypography>
      );
      break;
  }
  return revenues;
};

export const getCSVRevenuesValue = (value) => {
  let revenues = "No records on file";
  switch (value) {
    case "True":
      revenues = "Exceeds 3 MM dollars";
      break;
    case "False":
      revenues = "Does not exceed 3 MM dollars";
      break;
    default:
      revenues = "No records on file";
      break;
  }
  return revenues;
};

export const getOfficerEmails = (jsonData) => {
  let emails = [];
  try {
    const dataOfficer = JSON.parse(jsonData);
    const result = dataOfficer.map((officer) => (
      <span
        style={{
          display: "block",
          alignItems: "flex-start",
          flexDirection: "column",
          justifyContent: "flex-start",
          margin: 0,
          padding: 0,
        }}
      >
        <MDTypography variant="text" fontWeight="regular">
          <Icon color="info">person</Icon>{" "}
          {`  ${officer.name}   (${officer.title})     `}
        </MDTypography>
        <MDTypography variant="text" fontWeight="regular">
          <Icon color="warning">email</Icon> {`    ${officer.email}`}
        </MDTypography>
      </span>
    ));
    emails = [...new Set(result)];
  } catch (error) {
    emails = "No records on file";
  }
  return emails;
};

export const getCorpTelephone = (jsonData) => {
  let telephone = [];
  try {
    const dataCorp = JSON.parse(jsonData);
    if (Array.isArray(dataCorp)) {
      const result = dataCorp.map((corp) => (
        <MDTypography variant="text" fontWeight="regular">
          {<Icon color="info">phone</Icon>} {corp.telephone}
        </MDTypography>
      ));
      telephone = [...new Set(result)];
    } else {
      const dataCorp = JSON.parse(jsonData);
      telephone = dataCorp.telephone;
    }
  } catch (error) {
    telephone = "No records on file";
  }
  return telephone;
};

export const getCorpResidentAgentInfo = (jsonData) => {
  let telephone = [];
  try {
    const dataCorp = JSON.parse(jsonData);
    if (Array.isArray(dataCorp)) {
      const result = dataCorp.map((corp) => (
        <MDTypography variant="text" fontWeight="regular">
          {<Icon color="info">phone</Icon>} {corp.telephone}
        </MDTypography>
      ));
      telephone = [...new Set(result)];
    } else {
      const dataCorp = JSON.parse(jsonData);
      telephone = (
        <span
          style={{
            display: "block",
            alignItems: "flex-start",
            flexDirection: "column",
            justifyContent: "flex-start",
            margin: 0,
            padding: 0,
          }}
        >
          <MDTypography variant="text" fontWeight="regular">
            <Icon color="info">person</Icon> {dataCorp.name}
          </MDTypography>
          <MDTypography variant="text" fontWeight="regular">
            <Icon color="warning">phone</Icon> {dataCorp.telephone}
          </MDTypography>
        </span>
      );
    }
  } catch (error) {
    telephone = "No records on file";
  }
  return telephone;
};
