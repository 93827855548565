/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
// @mui material components
// import Tooltip from "@mui/material/Tooltip";
// Context Dashboard
import React, { useContext, useState } from "react";
import { AdvancedSearchContext } from "context/AdvancedSearchContext";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
// import HomeWorkIcon from "@mui/icons-material/HomeWork";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import { Icon } from "@mui/material";
import { formatter, normalizeDate } from "utils/util";
// import { Box } from "@mui/system";
import BusinessIcon from "@mui/icons-material/Business";
import { FormControl, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { getRevenuesValue } from "utils/util";
import { getOfficerEmails } from "utils/util";
import { getCorpTelephone } from "utils/util";
import { getCorpResidentAgentInfo } from "utils/util";

const SelectSmall = ({ options }) => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small">Test</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={value}
        placeholder="Email(s)"
        onChange={handleChange}
      >
        {options.map((op) => (
          <MenuItem value={op.email}>
            <spam>
              {op.name.replace(",", "").concat(" - ")}
              {op.email}
            </spam>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export function Data() {
  const { corpAdvanceList } = useContext(AdvancedSearchContext);

  /* const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Icon color="info" fontSize="medium">
        {image}
      </Icon>
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  ); */

  const CorpData = ({ value }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {value}
      </MDTypography>
    </MDBox>
  );

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Icon color="info" fontSize="medium">
        {image}
      </Icon>
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      {
        Header: "corporation name",
        accessor: "corp_name",
        width: "10%",
        align: "left",
        Cell: ({ value }) => (
          <>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "24rem",
              }}
            >
              <Company image={<HomeWorkIcon />} name={value || ""} noWrap />
            </div>
          </>
        ),
      },
      {
        Header: "Revenues",
        accessor: "corp_volume_business",
        width: "10%",
        align: "left",
        isVisible: true,
        Cell: ({ value }) =>
          value !== null ? (
            getRevenuesValue(value)
          ) : (
            <MDTypography variant="error" fontWeight="regular" color="error">
              No records on file
            </MDTypography>
          ),
      },
      {
        Header: "Officer emails",
        accessor: "corp_officers_certificated_filings_pdf",
        width: "15%",
        align: "left",
        isVisible: true,
        Cell: ({ value }) =>
          value !== null ? (
            getOfficerEmails(value)
          ) : (
            <MDTypography variant="error" fontWeight="regular" color="error">
              No records on file
            </MDTypography>
          ),
      },
      {
        Header: "Director emails",
        accessor: "corp_directors_certificated_filings_pdf",
        width: "15%",
        align: "left",
        isVisible: true,
        Cell: ({ value }) =>
          value !== null ? (
            getOfficerEmails(value)
          ) : (
            <MDTypography variant="error" fontWeight="regular" color="error">
              No records on file
            </MDTypography>
          ),
      },
      {
        Header: "formation date",
        accessor: "corp_formation_date",
        width: "10%",
        align: "left",
        Cell: ({ value }) => (
          <>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "10rem",
              }}
            >
              <MDTypography variant="button" fontWeight="regular" color="text">
                {normalizeDate(value)}
              </MDTypography>
            </div>
          </>
        ),
        isVisible: true,
      },
      {
        Header: "jurisdiction",
        accessor: "corp_jurisdiction",
        width: "10%",
        align: "left",
        isVisible: true,
      },
      {
        Header: "type",
        accessor: "corp_type",
        width: "10%",
        align: "left",
      },
      {
        Header: "total assets",
        accessor: "corp_total_assets",
        width: "10%",
        align: "left",
        Cell: ({ value }) => formatter.format(value),
      },
      {
        Header: "equity",
        accessor: "corp_equity",
        width: "10%",
        align: "left",
        Cell: ({ value }) => formatter.format(value),
      },
      {
        Header: "property & equipment",
        accessor: "corp_property_equipment",
        width: "10%",
        align: "left",
        Cell: ({ value }) => formatter.format(value),
      },
      {
        Header: "current liabilities",
        accessor: "corp_current_liabilities",
        width: "10%",
        align: "left",
        Cell: ({ value }) => formatter.format(value),
      },
      {
        Header: "Telephone",
        accessor: "corp_designated_office_address_annual_filings_pdf",
        width: "10%",
        align: "left",
        isVisible: true,
        Cell: ({ value }) =>
          value !== null ? (
            getCorpTelephone(value)
          ) : (
            <MDTypography variant="error" fontWeight="regular" color="error">
              No records on file
            </MDTypography>
          ),
      },
      {
        Header: "Resident Agent Contact Info",
        accessor: "corp_resident_agent_certificated_filings_pdf",
        width: "10%",
        align: "left",
        isVisible: true,
        Cell: ({ value }) =>
          value !== null ? (
            getCorpResidentAgentInfo(value)
          ) : (
            <MDTypography variant="error" fontWeight="regular" color="error">
              No records on file
            </MDTypography>
          ),
      },
      {
        Header: "Class",
        accessor: "corp_class",
        width: "10%",
        align: "left",
      },
    ],

    rows: corpAdvanceList.map((corp) => {
      const dataCorp = {
        corp_name: (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "8rem",
            }}
          >
            <MDTypography variant="button" fontWeight="medium" color="info">
              {corp.corp_name}
            </MDTypography>
          </div>
        ),
        corp_officers_certificated_filings_pdf: (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "12rem",
            }}
          >
            <CorpData
              value={corp.corp_officers_certificated_filings_pdf}
              noWrap
            />
          </div>
        ),
        corp_volume_business: (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "8rem",
            }}
          >
            <CorpData value={corp.corp_volume_business} noWrap />
          </div>
        ),
        formation_date: (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "12rem",
            }}
          >
            <CorpData value={corp.corp_formation_date || ""} noWrap />
          </div>
        ),
        total_assets: (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "8rem",
            }}
          >
            <CorpData value={corp.corp_total_assets || ""} noWrap />
          </div>
        ),
        equity: (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "8rem",
            }}
          >
            <CorpData value={corp.corp_equity || ""} noWrap />
          </div>
        ),
        property_equipment: (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "8rem",
            }}
          >
            <CorpData value={corp.corp_property_equipment || ""} noWrap />
          </div>
        ),
        current_liabilities: (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "8rem",
            }}
          >
            <CorpData value={corp.corp_current_liabilities || ""} noWrap />
          </div>
        ),
        type: (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "8rem",
            }}
          >
            <CorpData value={corp.corp_type || ""} noWrap />
          </div>
        ),
        jurisdiction: (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "8rem",
            }}
          >
            <CorpData value={corp.corp_jurisdiction || ""} noWrap />
          </div>
        ),
        corp_class: (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "8rem",
            }}
          >
            <CorpData value={corp.corp_class || ""} noWrap />
          </div>
        ),
        corp_resident_agent_certificated_filings_pdf: (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "8rem",
            }}
          >
            <CorpData
              value={corp.corp_resident_agent_certificated_filings_pdf || ""}
              noWrap
            />
          </div>
        ),
      };
      return dataCorp;
    }),
  };
}

export const storeCustomColumns = () => {
  const { columns } = Data();
  JSON.parse(localStorage.getItem("_ceyba_advanced_hidden_columns"));
};

export const defaultHiddenColumnsTable = [
  "corp_volume_business",
  "corp_formation_date",
  "corp_jurisdiction",
  "corp_type",
  "corp_total_assets",
  "corp_equity",
  "corp_property_equipment",
  "corp_current_liabilities",
  "corp_resident_agent_certificated_filings_pdf",
  "corp_officers_certificated_filings_pdf",
  "corp_directors_certificated_filings_pdf",
  "corp_designated_office_address_annual_filings_pdf",
  "corp_class",
];
