import { formatter } from "utils/util";

const defaultColumns = [
  { Header: "corporation name", accessor: "corp_name", Footer: "corporation name" },
  {
    Header: "total assets",
    accessor: "corp_total_assets",
    Footer: "total assets",
    Cell: ({ value }) => formatter.format(value),
  },
  {
    Header: "equity",
    accessor: "corp_equity",
    Footer: "equity",
    Cell: ({ value }) => formatter.format(value),
  },
  {
    Header: "property & equipment",
    accessor: "corp_property_equipment",
    Footer: "property & equipment",
    Cell: ({ value }) => formatter.format(value),
  },
  {
    Header: "current liabilities",
    accessor: "corp_current_liabilities",
    Footer: "current liabilities",
    Cell: ({ value }) => formatter.format(value),
  },
];

export default defaultColumns;
