/*
=========================================================
* Ceyba
=========================================================

Coded by Andres Lista

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the
Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// context for advance search
// import { AdvanceSearchContext } from "context/AdvanceSearchContext";

// @mui icons
/* import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CategoryIcon from "@mui/icons-material/Category"; */

// Ceyba Material Dashboard
import MDBox from "components/MDBox";

// Ceyba Material Dashboard
import DashboardLayout from "components_base/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components_base/Navbars/DashboardNavbar";
import Footer from "components_base/Footer";
// import ReportsBarChart from "components_base/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "components_base/Charts/LineCharts/ReportsLineChart";
/* import ComplexStatisticsCard from "components_base/Cards/StatisticsCards/ComplexStatisticsCard";
import ComplexCardPurpose from "components_base/Cards/StatisticsCards/ComplexCardPurpose"; */

// Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
/* import authorsTableData from "layouts/tables/data/authorsTableData";
import addressTableData from "layouts/tables/data/addressTableData"; */

// Dashboard components
import QuickSearchResults from "layouts/quick_search/components/QuickSearchResults";
/* import MDSelect from "components/MDSelect"; */
// import MDSelect from "components/MDSelect";
/* import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import MDTypography from "components/MDTypography"; */
// import { useContext } from "react";
/* import { Card } from "@mui/material";
import DataTableResults from "components_base/Tables/DataTableResults";
import AddressTableResults from "components_base/Tables/AddressTableResults"; */
// import formatter from "utils/util";

function QuickSearch() {
  return (
    <div data-aos="fade-in">
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox>
          <MDBox>
            <Grid container spacing={3} justifyContent="flex-start" alignItems="center">
              <Grid item xs={12} md={12} lg={12}>
                <QuickSearchResults />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </div>
  );
}

export default QuickSearch;
