// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
/* import Icon from "@mui/material/Icon"; */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ComplexCardPurpose({ color, title, purposeInfo, updateInfo /* icon */ }) {
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="lg"
          display="flex"
          position="absolute"
          justifyContent="left"
          alignItems="center"
          minWidth="8rem"
          height="2.5rem"
          mt={-3}
          py={2}
          px={2}
        >
          <MDTypography variant="h6" color="white" fontSize="1rem">
            Corporation Purpose
          </MDTypography>
        </MDBox>
        <MDBox mt={2} position="relative">
          <MDBox textAlign="left" lineHeight={1.25} mt={2}>
            <MDTypography variant="h6" fontSize="1.2rem">
              {title}
            </MDTypography>
            <MDTypography variant="button" align="left">
              {purposeInfo}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox pb={2} px={2}>
        <MDTypography component="p" variant="button" color="text" display="flex">
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={updateInfo.color}
          >
            {updateInfo.descriptionUpdate}
          </MDTypography>
          &nbsp;{updateInfo.descriptionTime}
        </MDTypography>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexCardPurpose.defaultProps = {
  color: "info",
  title: "",
  updateInfo: {
    color: "success",
    descriptionUpdate: "",
    descriptionTime: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexCardPurpose.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string,
  purposeInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  updateInfo: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    descriptionUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    descriptionTime: PropTypes.string,
  }),
  /* icon: PropTypes.node.isRequired, */
};

export default ComplexCardPurpose;
