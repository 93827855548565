// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";

function ComplexStatisticsCard({ color, title, count, percentage, icon }) {
  const [raised, setRaised] = useState("none");

  const handleElevationIn = () => {
    setRaised("4");
  };

  const handleElevationOut = () => {
    setRaised("none");
  };

  return (
    <div>
      <Card
        onMouseEnter={handleElevationIn}
        onMouseLeave={handleElevationOut}
        sx={{ boxShadow: raised }}
      >
        <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
          <MDBox textAlign="left" lineHeight={1.25} width={icon === null ? "100%" : "auto"}>
            <MDTypography variant="button" fontWeight="medium" color="text" py={1}>
              {title}
            </MDTypography>
            <MDTypography variant="h4" py={1}>
              {count}
            </MDTypography>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox pb={2} px={2}>
          <MDTypography component="p" variant="button" color="text" display="flex">
            <MDTypography
              component="span"
              variant="button"
              fontWeight="bold"
              color={percentage.color}
            >
              {percentage.amount}
            </MDTypography>
            &nbsp;{percentage.label}
          </MDTypography>
        </MDBox>
      </Card>
    </div>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
