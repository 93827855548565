import { createContext, useContext, useReducer } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { setCookie, getCookie } from "utils/util";

// The UI Dashboard main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "sign-in",
    darkMode: JSON.parse(getCookie("ceyba-dark-mode-theme") || false),
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  return <MaterialUI.Provider value={[controller, dispatch]}>{children}</MaterialUI.Provider>;
}

// Material Dashboard
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
/**
 * It takes a dispatch function and a value, and then dispatches an action with the type MINI_SIDENAV
 * and the value.
 * @param dispatch - This is the dispatch function that is passed to the component by the connect
 * function.
 * @param value - The value to be set to the state.
 */
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });

/**
 * It takes a dispatch function and a value, and then dispatches an action with the type
 * TRANSPARENT_SIDENAV and the value
 * @param dispatch - The dispatch function from the redux store.
 * @param value - The value to be set.
 */
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });

/**
 * It takes a dispatch function and a value, and then dispatches an action with the type WHITE_SIDENAV
 * and the value.
 * @param dispatch - This is the dispatch function that is passed to the component by the connect
 * function.
 * @param value - The value to be set to the state
 */
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });

const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });

/**
 * It sets a cookie with the name "ceyba-dark-mode-theme" and the value of the parameter "value" for 24
 * hours. Then it dispatches an action to the reducer with the type "DARKMODE" and the value of the
 * parameter "value"
 * @param dispatch - This is the dispatch function that is passed to the component by the useReducer
 * hook.
 * @param value - The value of the cookie.
 */
const setDarkMode = (dispatch, value) => {
  setCookie("ceyba-dark-mode-theme", value, 24);
  dispatch({ type: "DARKMODE", value });
};

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
};
