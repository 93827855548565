/* eslint-disable react/prop-types */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Card } from "@mui/material";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function MDModal({ title, content, isOpen, onClose }) {
  return (
    <Dialog
      open={isOpen}
      sx={{ borderRadius: 8 }}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <Card>
        <DialogTitle mt={2}>{title}</DialogTitle>
        <DialogContent mt={2}>{content}</DialogContent>
      </Card>
    </Dialog>
  );
}

//
// title content isOpen onClose
