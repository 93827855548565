import React, { forwardRef, Fragment } from "react";
// import Dashboard from "layouts/dashboard";
// import MDCheckAuthRoute from "components/MDCheckAuthRoute";
import { Navigate } from "react-router-dom";
import auth from "../../authentication/fbAuthentication";

// eslint-disable-next-line react/prop-types
const MDProtectedRoute = forwardRef(({ children, ...rest }, ref) => {
  if (auth.isAuthenticated()) {
    return (
      <Fragment ref={ref} {...rest}>
        {children}
      </Fragment>
    );
  }
  return <Navigate to="/authentication/sign-in" />;
});

export default MDProtectedRoute;
