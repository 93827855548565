// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/firebase-auth";
import "firebase/firebase-firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAmv3ep1bc_rpd2KGrP4XvjH07zLCEv8p4",
  authDomain: "ceyba-master-v1.firebaseapp.com",
  projectId: "ceyba-master-v1",
  storageBucket: "ceyba-master-v1.appspot.com",
  messagingSenderId: "42738928433",
  appId: "1:42738928433:web:be18dff615546aa4243e10",
  measurementId: "G-TDZTMD2L1D",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const fbAuth = app.auth();
export const fbStore = app.firestore();
