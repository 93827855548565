import React, { createContext, useState } from "react";

import PropTypes from "prop-types";

export const DashboardContext = createContext();

/**
 * The DashboardProvider is a function that returns a DashboardContext.Provider component.
 * @returns The DashboardProvider is returning the DashboardContext.Provider.
 */
export const DashboardProvider = ({ children }) => {
  const [corpList, setCorpList] = useState([]);
  const [corpSelected, setCorpSelected] = useState(false);
  const [isSearchLoading, setSearchLoading] = useState(false);

  return (
    <DashboardContext.Provider
      value={{
        corpList,
        setCorpList,
        corpSelected,
        setCorpSelected,
        isSearchLoading,
        setSearchLoading,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

// Defaults props
DashboardProvider.defaultProps = {
  children: "",
};

// Typechecking props
DashboardProvider.propTypes = {
  children: PropTypes.node,
};
