import * as React from "react";
// import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// import MenuItem from "@mui/material/MenuItem";
// import MDTypography from "components/MDTypography";

// eslint-disable-next-line react/prop-types
export default function MDMenuTable({ menu }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MDBox display="flex" justifyContent="flex-end" marginRight={8}>
      <MDButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
        color="info"
        variant="gradient"
        sx={{ borderRadius: 2 }}
      >
        show/hide columns
      </MDButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menu}
      </Menu>
    </MDBox>
  );
}
