/*
=========================================================
* Ceyba
=========================================================

Coded by Andres Lista

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the
Software.
*/

import { useContext, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
/* import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem"; */

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Soft UI Dashboard Materail-UI example components
import DataTableAdvanced from "components_base/Tables/DataTableAdvanced";

// Data
import {
  Data,
  defaultHiddenColumnsTable,
} from "layouts/advance_search/components/AdvancedSearchResults/data";

// Context Dashboard
import { AdvancedSearchContext } from "context/AdvancedSearchContext";
import { CircularProgress, Menu, MenuItem } from "@mui/material";

function AdvanceSearchResults() {
  const { columns, rows } = Data();
  const [menu, setMenu] = useState(null);
  const { corpAdvanceList, isAdvanceSearchLoading } = useContext(AdvancedSearchContext);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Search Results
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              color={corpAdvanceList.length <= 0 ? "warning" : "success"}
              fontSize="large"
              sx={{
                fontWeight: "bold",
                mt: -0.5,
              }}
            >
              {corpAdvanceList.length <= 0 ? "warning" : "done"}
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>{corpAdvanceList.length}</strong> corporation found
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox color="text" px={1} display="none">
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </MDBox>
        {renderMenu}
      </MDBox>
      <MDBox>
        {isAdvanceSearchLoading ? (
          <MDBox
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={6}
          >
            <CircularProgress color="info" />
          </MDBox>
        ) : (
          <MDBox
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={0}
          >
            {corpAdvanceList.length <= 0 ? (
              <MDBox padding={2}>
                <MDTypography variant="button" fontWeight="regular" color="text" width="100%">
                  <strong>No matches found</strong>
                </MDTypography>
              </MDBox>
            ) : (
              <DataTableAdvanced
                table={{ columns, rows }}
                canSearch
                isSorted
                showTotalEntries
                noEndBorder
                customHiddenColumns={
                  JSON.parse(localStorage.getItem("_ceyba_advanced_hidden_columns")) ||
                  defaultHiddenColumnsTable
                }
                pagination="gradient"
                entriesPerPage
              />
            )}
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}

export default AdvanceSearchResults;
