import {
  useMemo,
  useEffect,
  useState,
  useContext,
  forwardRef,
  useRef,
} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useColumnOrder,
} from "react-table";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/util";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
// import Autocomplete from "@mui/material/Autocomplete";

// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";

// Material Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import MDMenuTable from "components/MDMenuTable/MDMenuTable";

// Context Dashboard
import { DashboardContext } from "context/DashboardContext";
import { AdvancedSearchContext } from "context/AdvancedSearchContext";

// Material Dashboard 2 React example components
import DataTableHeadCell from "components_base/Tables/DataTableAdvanced/DataTableHeadCell";
import DataTableBodyCell from "components_base/Tables/DataTableAdvanced/DataTableBodyCell";
import { Autocomplete, Box, Checkbox, Grid, TextField } from "@mui/material";
import { normalizeDate, capitalizeStr } from "utils/util";
import { getCSVRevenuesValue } from "utils/util";

// import defaultColumns from "layouts/advance_search/components/AdvancedSearchTable/columns";

function DataTableAdvanced({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  customHiddenColumns,
  noEndBorder,
}) {
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 10;
  const { corpAdvanceList, setAdvancedDataCSV } = useContext(
    AdvancedSearchContext
  );
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["10", "20", "30", "50", "100"];
  const columns = useMemo(() => table.columns, []);
  const data = useMemo(() => corpAdvanceList, []);
  const tableHiddenColumns = customHiddenColumns || [];
  const { setCorpSelected } = useContext(DashboardContext);
  const navigate = useNavigate();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    getToggleHideAllColumnsProps,
    allColumns,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    visibleColumns,
    // setHiddenColumns,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 50,
        hiddenColumns: tableHiddenColumns,
      },
      usePagination,
      autoResetHiddenColumns: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useColumnOrder
  );

  // Set the default value for the entries per page when component mounts
  useEffect(() => {
    setPageSize(defaultValue);
  }, [defaultValue, setPageSize]);

  useEffect(() => {
    const hiddenColumns = allColumns
      .filter((col) => !col.isVisible && col.id)
      .map((col) => col.id);
    localStorage.setItem(
      "_ceyba_advanced_hidden_columns",
      JSON.stringify(hiddenColumns)
    );
  }, [visibleColumns, allColumns]);

  useEffect(() => {
    // Set the data to generate CSV:
    setAdvancedDataCSV(
      rows.map((row) => {
        const rowResult = {
          corp_name: row.values.corp_name,
          corp_volume_business:
            row.values.corp_volume_business !== null
              ? getCSVRevenuesValue(row.values.corp_volume_business)
              : "No records on file",
          corp_formation_date:
            row.values.corp_formation_date !== null
              ? normalizeDate(row.values.corp_formation_date)
              : "No records on file",
          corp_jurisdiction: row.values.corp_jurisdiction,
          corp_type: row.values.corp_type,
          corp_total_assets:
            row.values.corp_total_assets !== null
              ? row.values.corp_total_assets
              : "No records on file",
          corp_equity:
            row.values.corp_equity !== null
              ? row.values.corp_equity
              : "No records on file",
          corp_property_equipment: row.values.corp_property_equipment
            ? row.values.corp_property_equipment
            : "No records on file",
          corp_current_liabilities:
            row.values.corp_current_liabilities !== null
              ? row.values.corp_current_liabilities
              : "No records on file",
          corp_class: row.values.corp_class,
          corp_officers_certificated_filings_pdf:
            row.values.corp_officers_certificated_filings_pdf !== null
              ? JSON.parse(
                  row.values.corp_officers_certificated_filings_pdf
                ).map(
                  (officer) =>
                    `${capitalizeStr(officer.name.replace(",", " "))} : ${
                      officer.email
                    }`
                )
              : "No records on file",
          corp_directors_certificated_filings_pdf:
            row.values.corp_directors_certificated_filings_pdf !== null
              ? JSON.parse(
                  row.values.corp_directors_certificated_filings_pdf
                ).map(
                  (officer) =>
                    `${capitalizeStr(officer.name.replace(",", " "))} : ${
                      officer.email
                    }`
                )
              : "No records on file",
          corp_designated_office_address_annual_filings_pdf:
            row.values.corp_designated_office_address_annual_filings_pdf !==
            null
              ? JSON.parse(
                  row.values.corp_designated_office_address_annual_filings_pdf
                ).map((address) => address.telephone)
              : "No records on file",
          corp_resident_agent_certificated_filings_pdf:
            row.values.corp_resident_agent_certificated_filings_pdf !== null
              ? `${
                  JSON.parse(
                    row.values.corp_resident_agent_certificated_filings_pdf
                  ).name
                } : ${
                  JSON.parse(
                    row.values.corp_resident_agent_certificated_filings_pdf
                  ).telephone
                }`
              : "No records on file",
        };
        return rowResult;
      })
    );
  }, [columns, rows, data, setAdvancedDataCSV]);

  // Set the entries per page value based on the selected value
  const setEntriesPerPage = (event, value) => {
    setPageSize(value);
  };

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0
      ? gotoPage(0)
      : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) =>
    gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart =
    pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const handleRowClick = (row) => {
    setCorpSelected(corpAdvanceList[row.id]);
    navigate("/dashboard");
  };

  // eslint-disable-next-line react/prop-types
  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return <Checkbox type="checkbox" ref={resolvedRef} {...rest} />;
    }
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <MDMenuTable
            menu={
              <MDBox>
                <MenuItem>
                  <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
                  <MDTypography
                    variant="button"
                    fontWeight="medium"
                    ml={1}
                    lineHeight={1}
                  >
                    TOGGLE ALL
                  </MDTypography>
                </MenuItem>
                {allColumns.map((column) => (
                  <MenuItem>
                    <MDBox key={column.id}>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label>
                        <Checkbox
                          type="checkbox"
                          {...column.getToggleHiddenProps()}
                          {...column.getHeaderProps(
                            isSorted && column.getSortByToggleProps()
                          )}
                        />
                        <MDTypography
                          variant="button"
                          fontWeight="medium"
                          ml={1}
                          lineHeight={1}
                        >
                          {capitalizeFirstLetter(column.Header)}
                        </MDTypography>
                      </label>
                    </MDBox>
                  </MenuItem>
                ))}
              </MDBox>
            }
          />
          {entriesPerPage || canSearch ? (
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              p={3}
            >
              {entriesPerPage && (
                <MDBox display="flex" alignItems="center">
                  <Autocomplete
                    disablePortal
                    disableClearable
                    options={entries}
                    size="small"
                    sx={{ width: "8.5rem" }}
                    onChange={(event, newValue) => {
                      setEntriesPerPage(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        value={pageSize.toString()}
                        label="entries per page"
                        {...params}
                      />
                    )}
                  />
                  <MDTypography variant="caption" color="secondary">
                    &nbsp;&nbsp;entries per page
                  </MDTypography>
                </MDBox>
              )}

              {canSearch && (
                <MDBox width="12rem" ml={4}>
                  <MDInput
                    placeholder="Search..."
                    value={search}
                    size="small"
                    fullWidth
                    onChange={({ currentTarget }) => {
                      setSearch(search);
                      onSearchChange(currentTarget.value);
                    }}
                  />
                </MDBox>
              )}
            </Box>
          ) : null}

          <TableContainer sx={{ boxShadow: "none" }}>
            <Table {...getTableProps()}>
              <MDBox component="thead">
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <DataTableHeadCell
                        {...column.getHeaderProps(
                          isSorted && column.getSortByToggleProps()
                        )}
                        width={column.width ? column.width : "auto"}
                        align={column.align ? column.align : "left"}
                        sorted={setSortedValue(column)}
                      >
                        <MDTypography
                          variant="button"
                          fontWeight="medium"
                          textTransform="uppercase"
                          color="dark"
                        >
                          {column.render("Header")}
                        </MDTypography>
                      </DataTableHeadCell>
                    ))}
                  </TableRow>
                ))}
              </MDBox>
              <TableBody {...getTableBodyProps()}>
                {page.map((row, key) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      hover
                      {...row.getRowProps()}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(row)}
                    >
                      {row.cells.map((cell) => (
                        <DataTableBodyCell
                          noBorder={noEndBorder && rows.length - 1 === key}
                          align={cell.column.align ? cell.column.align : "left"}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </DataTableBodyCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            <MDBox
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
            >
              {showTotalEntries && (
                <MDBox mb={{ xs: 3, sm: 0 }}>
                  <MDTypography
                    variant="button"
                    color="secondary"
                    fontWeight="regular"
                  >
                    Showing {entriesStart} to {entriesEnd} of {rows.length}{" "}
                    entries
                  </MDTypography>
                </MDBox>
              )}
              {pageOptions.length > 1 && (
                <MDPagination
                  variant={pagination.variant ? pagination.variant : "gradient"}
                  color={pagination.color ? pagination.color : "info"}
                >
                  {canPreviousPage && (
                    <MDPagination item onClick={() => previousPage()}>
                      <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                    </MDPagination>
                  )}
                  {renderPagination.length > 6 ? (
                    <MDBox width="5rem" mx={1}>
                      <MDInput
                        inputProps={{
                          type: "number",
                          min: 1,
                          max: customizedPageOptions.length,
                        }}
                        value={customizedPageOptions[pageIndex]}
                        onChange={
                          (handleInputPagination, handleInputPaginationValue)
                        }
                      />
                    </MDBox>
                  ) : (
                    renderPagination
                  )}
                  {canNextPage && (
                    <MDPagination item onClick={() => nextPage()}>
                      <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                    </MDPagination>
                  )}
                </MDPagination>
              )}
            </MDBox>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}

// Setting default values for the props of DataTable
DataTableAdvanced.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  customHiddenColumns: ["corporation_name"],
};

// Typechecking props for the DataTable
DataTableAdvanced.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  customHiddenColumns: PropTypes.arrayOf(String),
};

export default DataTableAdvanced;
