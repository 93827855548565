/* eslint-disable react/prop-types */
// @mui material components
// import Tooltip from "@mui/material/Tooltip";

// Context Dashboard
import { useContext } from "react";
import { DashboardContext } from "context/DashboardContext";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import HomeWorkIcon from "@mui/icons-material/HomeWork";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Icon } from "@mui/material";

export default function Data() {
  const { corpList } = useContext(DashboardContext);

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Icon color="info" fontSize="medium">
        {image}
      </Icon>
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [{ Header: "corporation name", accessor: "corp_name", width: "100%", align: "left" }],

    rows: corpList.map((corp) => {
      const dataCorp = {
        corp_name: (
          <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: "24rem" }}>
            <Company image={<HomeWorkIcon />} name={corp.corp_name} noWrap />
          </div>
        ),
      };
      return dataCorp;
    }),
  };
}
