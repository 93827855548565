// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Timeline context
import { useTimeline } from "components_base/Timeline/context";

// Custom styles for the TimelineItem
import timelineItem from "components_base/Timeline/TimelineItem/styles";
import { Link } from "@mui/material";

// Icons
import DownloadIcon from "@mui/icons-material/Download";

function TimelineItem({ color, icon, title, url, description, lastItem }) {
  const isDark = useTimeline();

  return url !== "#" && url !== "no records on file" ? (
    <MDBox
      position="relative"
      mb={3}
      sx={(theme) => timelineItem(theme, { lastItem, isDark })}
      display={url !== "#" ? "flex" : "none"}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Icon fontSize="inherit">{icon}</Icon>
      </MDBox>
      <MDBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
        <MDTypography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"}>
          {title}
        </MDTypography>
        <Link
          href={url !== "no records on file" ? url : "#"}
          rel="noreferrer"
          style={{
            display: url !== "no records on file" ? "flex" : "none",
          }}
        >
          <MDBox mt={0.5} wrap justifyContent="center" alignItems="center">
            <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: "11rem" }}>
              <MDBox mt={0.5} justifyContent="center" alignItems="center" direction="row">
                <MDTypography variant="caption" color={isDark ? "secondary" : "info"} noWrap>
                  Download PDF
                </MDTypography>
                <DownloadIcon color="info" fontSize="small" />
              </MDBox>
            </div>
          </MDBox>
        </Link>
        <MDBox mt={2} mb={1.5}>
          {description ? (
            <MDTypography variant="button" color={isDark ? "white" : "dark"} wrap>
              {description}
            </MDTypography>
          ) : null}
        </MDBox>
      </MDBox>
    </MDBox>
  ) : (
    <MDBox ml={6}>
      <MDTypography variant="button" color="error" align="left">
        No records on file
      </MDTypography>
    </MDBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  lastItem: false,
  description: "",
  url: "#",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  description: PropTypes.string,
  lastItem: PropTypes.bool,
};

export default TimelineItem;
